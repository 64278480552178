import React, { useState } from 'react';
import axios from 'axios';
// import { Panel, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {baseUrl} from '../../config';
console.log(baseUrl)

const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};


function LoginForm(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [loginMsg,setLoginMsg]=useState('')
  if(localStorage.getItem('isLogin')==="true"){
   history.push('/home');
  }

  const handleLogin = () => {
    setLoading(false);
    
    if(username.value =='' || password.value ==''){
      setLoginMsg('Please input the valid credentials');
    }else{
      setLoginMsg('')
      axios.post(baseUrl+'/auth/login', { email: username.value, password: password.value }).then(response => {
        if(response.data.success==false){
          setLoginMsg('Incorrect credentials');
        }else if(response.data.success==true){
          setLoading(false);
          localStorage.setItem('userdata', JSON.stringify(response.data.result));
          localStorage.setItem('isLogin', true);
          localStorage.setItem('token',response.data.token)
          window.location.href='/home';
        }else{
          
          setLoading(false);
          setLoginMsg('Something went wrong');
        }
       
      }).catch(error => {
        setLoading(false);
        setLoginMsg('Something went wrong');
        
      });
    }
   
  }

  
 

 
    return (
      <section className="bg-back">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="overlay-content popup1">
              <form>
                <h1>Login</h1>
                <p style={errorMsgStyle}>{loginMsg}</p>
                <div className="field">
                  <input type="text" {...username}  required placeholder="abc@gmail.com" />
                  <span className="fa fa-envelope" />
                </div>
                <div className="field">
                  <input type="password" {...password}  required placeholder="Password" />
                  <span className="fa fa-eye-slash" />
                </div>
                {/* <span className="forgot-pss">Forgot Password</span> */}
              </form>
              <button className="close-btn" onClick={handleLogin} disabled={loading}>Login</button>
              
            </div>        
          </div>
        </div>
      </div>
    </section>
    )
  
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default LoginForm;