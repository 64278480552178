import React, { useState } from 'react';
import axios from 'axios';
import axiosIntance,{callAPI} from '../../helper/axios';
import { useHistory } from "react-router-dom";
import {baseUrl} from '../../config';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { clickColor, inArray,validateEmail } from './../../library/util';
const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};
const successMsgStyle = {
  color: 'green',
  textAlign: 'center'
};

const selectBoxStyle = {
  width: '100%'
}


function AddFormOrder(props) {
  const history = useHistory();
  const api_module='order-management';
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const sidebaretype=userdata?.type;
  if(sidebaretype!='admin' && sidebaretype!='superadmin' && sidebaretype!='owneradmin'){
  history.push('/')  
 }


  const handleSignOut = () => {
      console.log('logout')
      window.localStorage.setItem('isLogin',false);
      history.push('/');
  }
  const backToOrderManagement=()=>{
    history.push('/manageNotableOrder');
  }

  const [loading, setLoading] = useState(false);
  
  const [loginMsg,setLoginMsg]=useState('')
  const [successMsg,setSuccessMsg]=useState('');


  const orderId = useFormInput('');
  const type = useFormInput('');
  const orderType = useFormInput('');

 

  const handleSubmit = async() => {  
    setLoading(false);
    if(orderId.value ==''){
      setLoginMsg('Please enter order Id')
    }if(orderType.value ==''){
      setLoginMsg('Please enter Order type')
    }if(type.value ==''){
      setLoginMsg('Please enter type')
    }else{
      try{

      }catch(e){
        setLoading(false);
        setLoginMsg('Something went wrong');
      }
      const response=await callAPI('POST', api_module, { order_id:orderId.value,order_type:orderType.value,source_of_order:type.value });
      if(response.data.success==true) {
        setSuccessMsg(response.data.message);
        setLoginMsg('');
        setTimeout(() => {
          setSuccessMsg('');
        }, 5000);
        
      }else if(response.data.success==false){
        setLoginMsg(response.data.message);
      }else{
        console.log('In else 50')
      }
    }
   
  }

  
 

 
    return (
      // <section className="bg-back">
      <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="overlay-content popup1">
              <form>
                <h1>Add Notable Order</h1>
                <p style={errorMsgStyle}>{loginMsg}</p>
                <p style={successMsgStyle}>{successMsg}</p>
                <div className="field">
                  <input type="text" {...orderId}  required placeholder="Order Id/Number"   />
                </div>
                <div className="field">
                 <select name="type" {...type} placeholder="type" style={selectBoxStyle} >
                 <option value="">Sources of Order..</option>
                  <option value="wowsuite">Wowsuite</option>
                  <option value="shopware">ShopWare</option>
                  </select>
                </div>
                <div className="field">
                 <select name="type" {...orderType} placeholder="Order Type" style={selectBoxStyle} >
                 <option value="">Order Types...</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="duplicate">Duplicate</option>
                  <option value="chargeback">Chargeback</option>
                  </select>
                </div>
              </form>
              <button className="close-btn" onClick={handleSubmit} disabled={loading}>Save</button>

              <button className="close-btn" onClick={backToOrderManagement} disabled={loading}>Back</button>
              
            </div>        
          </div>
        </div>
      </div>
      
      </div>
    // </section>
    )
  
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default AddFormOrder;