import React, { useState, useEffect } from 'react';
import { callAPI } from '../../helper/axios';
import { useHistory } from "react-router-dom";
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { clickColor, inArray,validateEmail } from './../../library/util';
import Loader from "react-loader-spinner";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};
// Modal.setAppElement('#yourAppElement');

function Users() {
  const api_module='users';
  const [showSection, setShowSection] = useState(true);
  let editDeleteIcon = true;

  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedrowList, setSelectedRowList] = useState([]);


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }


  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const sidebaretype = userdata.type;
  if (sidebaretype != 'admin' && sidebaretype != 'superadmin' && sidebaretype != 'owneradmin') {
    history.push('/')
  }

  if (sidebaretype == 'superadmin') {
    editDeleteIcon = false;
  }



  /*** for User  form  */

  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const email = useFormInput('');
  const type = useFormInput('');

  // const [error, setError] = useState(null);
  const [loginMsg, setLoginMsg] = useState('')


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }


  const handleSubmit = async () => {

    setLoading(false);

    if (username.value == '') {
      setLoginMsg('Please enter username')
    }
    else if (password.value == '') {
      setLoginMsg('Please enter password')
    }
    else if (email.value == '') {
      setLoginMsg('Please enter email')
    }
    else if (type.value == '') {
      setLoginMsg('Please choose type')
    } else {

      try {
        const response = await callAPI('POST', api_module, { username: username.value, password: password.value, email: email.value, type: type.value })
        if (response.data.success == true) {
          window.location.href = '/home';
        } else if (response.data.success == false) {
          setLoginMsg(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        setLoginMsg('Something went wrong');
      }
    }

  }





  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  const currentUserData = JSON.parse(localStorage.getItem('userdata'));
  const currentUserId = currentUserData.id;


  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }
  const [cssCodesData, setcssCodesData] = useState([])

  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];

  useEffect(() => {
    loadData();

    return () => {
      console.log('un subscribe')
    }
  }, [])

  let loadData = async () => {
    try {
      const response = await callAPI('GET', api_module, {})
      if (response.data.result.length > 0) {
        response.data.result.map((item, i) => {
          
          response.data.result[i].showeditdelete = false;
          if (item.type == 'superadmin') {
            response.data.result[i].showeditdelete = true;
          }
          // if(sidebaretype=='owneradmin' && item.type=='admin'){
          //   response.data.result[i].showeditdelete = false;
          // }
          if(sidebaretype=='admin' && item.type=='owneradmin'){
            response.data.result[i].showeditdelete = true;
          }
        });
        console.log(response.data.result);
        setcssCodesData(response.data.result);
      }
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async (rowData) => {
    try {
      const response = await callAPI('DELETE', `${api_module}/${rowData.id}`, {})
      console.log(response)
      setSuccessMsg('User deleted!');
      if (currentUserId == rowData.id) {
        handleSignOut();
      }
    } catch (e) {
      setMessage('Something went wrong');
    }
  }

  const SubmitDelete = (rowData) => {
    confirmAlert({
      title: '',
      message: 'Are you sure want to delete the user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDataFn(rowData)
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }

  const addUserFn = (e) => {
    history.push('/addUser');
    // window.location.href = '/addUser';
  }

  const EditUserFn = (data) => {
    //let encodeId = encode(data.id) // s%p4ts4x%e5 
    var encodedString = btoa(data.id);
    console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"
    history.push('/editUser/' + encodedString);
    //history.push('/editUser/'+data.id);
  }



  return (
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />


      <div className='delete-div'>
        {
          // cssCodesData.length>0 ? <button type="button"  onClick={SubmitDelete} className="btn btn-primary">Delete All </button>:''

        }

      </div>

      <div className='delete-div'>
        {
          cssCodesData.length > 0 ? <button type="button" onClick={addUserFn} className="btn btn-primary">Add User</button> : ''

        }

      </div>
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>


      <section id="content-wrapper">
        <MaterialTable
          // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

          onRowClick={((evt, selectedRow) =>
            setRowBackground(selectedRow)
          )}

          columns={[
            { title: "Username", field: "username" },
            { title: "Email", field: "email" },
            { title: "Password", field: "password" },
            { title: "Type", field: "type" }
          ]}
          data={
            cssCodesData
          }
          title="Users"

          actions={[
            rowData => ({
              icon: 'edit',
              // iconProps: { style: { fontSize: "20px", color: "green" } },
              tooltip: 'Edit User',
              onClick: (event, rowData) => EditUserFn(rowData),
              disabled: rowData.showeditdelete
            }),
            // {
            //   icon: 'delete',
            //   tooltip: 'Delete User',
            //   onClick: (event, rowData) => SubmitDelete(rowData),
            //   disabled:editDeleteIcon,
            //   disabled:rowData.id
            // },
            //rowData.id==currentUserId
            rowData => ({
              icon: 'delete',
              // iconProps: { style: { fontSize: "20px", color: "red" } },
              tooltip: 'Delete User',
              onClick: (event, rowData) => SubmitDelete(rowData),
              //disabled:editDeleteIcon,
              disabled: rowData.showeditdelete
            })
          ]}
          options={{
            actionsColumnIndex: -1,
            addRowPosition: "first",
            maxBodyHeight: '500px',
            headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
            })
            // pageSize:10
          }}
        />
      </section>


      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >


        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div className="overlay-content popup1">
                <form>
                  <h1>Add User</h1>
                  <p style={errorMsgStyle}>{loginMsg}</p>
                  <div className="field">
                    <input type="text" {...username} required placeholder="Username" />
                    <span className="fa fa-envelope" />
                  </div>
                  <div className="field">
                    <input type="password" {...password} required placeholder="Password" />
                    <span className="fa fa-eye-slash" />
                  </div>
                  <div className="field">
                    <input type="text" {...email} required placeholder="abc@gmail.com" />
                    <span className="fa fa-envelope" />
                  </div>
                  <div className="field">
                    <select name="type" {...type} placeholder="type">
                      <option value="">Choose</option>
                      {sidebaretype == 'superadmin' &&
                        <option value="superadmin">Superadmin</option>
                      }
                      {(sidebaretype == 'admin' || sidebaretype == 'superadmin') &&
                        <option value="admin">Admin</option>
                      }
                      {(sidebaretype == 'admin' || sidebaretype == 'superadmin' || sidebaretype == 'subadmin') &&
                        <option value="subadmin">Sub-Admin</option>
                      }

                      <option value="user">User</option>
                    </select>
                    <span className="fa fa-envelope" />
                  </div>
                  {/* <span className="forgot-pss">Forgot Password</span> */}
                </form>
                <button className="close-btn" onClick={handleSubmit} disabled={loading}>Save</button>


              </div>
            </div>
          </div>
        </div>
      </Modal>




    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}



export default Users
