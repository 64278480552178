import React, { Component,useEffect } from 'react';
import LoginForm from './LoginForm';
import axios from 'axios';

import './LoginPage.css';






function LoginPage() {
    useEffect(() => {
      


    }, [])
    return (
      <div className="LoginPage">
        <LoginForm />
      </div>
    );
  }
 



export default LoginPage;