import axios from 'axios'; 
import { baseUrl } from '../config';
//import { useHistory } from "react-router-dom";
const token = window.localStorage.getItem('token');

const axiosIntance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Authorization': token ? `Bearer ${token}` : ''
    }
});

axiosIntance.interceptors.request.use((req) => {
    if(token){
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
})

axiosIntance.interceptors.response.use((res) => {
    
    return res;
}, (error) => { 
    const status=error.response.status;
    if(status && status===401){
        localStorage.clear();
        window.location.href="/"
    }
    return Promise.reject(error);
})

axiosIntance.defaults.timeout = 36000000;

function updateOptions(options) {
    const update = { ...options };
    if (token) {
      update.headers = {
        ...update.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return update;
  }
  
  export async function fetchInstance(url, options) {
    try{
        let fetcherres=await fetch(url, updateOptions(options));
        if(fetcherres.status===401){
            localStorage.clear();
            window.location.href="/"
        }else{
            return fetcherres;
        }
        
    }catch(e){
        console.log(e)
        localStorage.clear();
        window.location.href="/"
    }
    
  }

export async function callAPI(method,endpoint,params){
    if(params==null || params==undefined){
        params={};
    }   
    const result = await axiosIntance({
        method: method,
        url:baseUrl+`/${endpoint}`,
        data:params
    });
    return result;
}
 export default axiosIntance;
 