import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useHistory,useLocation } from "react-router-dom";
import { useParams } from "react-router-dom"
import MaterialTable from "material-table";
import Sidebar from '../../../../Template/Sidebar/Sidebar';
import Header from '../../../../Template/Header/Header';
import { baseUrl } from '../../../../../config';
import DatePicker from "react-datepicker";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import Typography from '@mui/material/Typography';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { clickColor, inArray } from './../../../../../library/util';
import './../TieredNew/Complete.css'
import './../../css/profit.css'
import './../../css/tiered.css'


import { formatNumberWithToFixed } from '../commanFn';
// import SelectChildGroup from "./SelectChildGroup";

// import Slider from '@mui/material/Slider';

import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
//import Box from '@mui/material/Box';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import CloseIcon from '@mui/icons-material/Close';
import { flexbox } from '@mui/system';
// import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';
// import Box from '@mui/material/Box';

import objAdvancedFilter from '../../../../../library/advancedFilterLibFn';
const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: 'white'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'lightblue'
      : isSelected
        ? '#2a7bc0'
        : undefined,
    zIndex: 1
  }),
  menu: base => ({
    ...base,
    zIndex: 100
  })
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AddIconClasses = { color: '#337ab7', fontSize: '30px', marginLeft: '220px', marginTop: "-15px", cursor: 'pointer' };
const RestoreIconClasses = { color: '#337ab7', fontSize: '20px', marginLeft: '5px', marginBottom: "-5px", cursor: 'pointer' };
const style_advanced = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// const popupFilterSection={
//   display:flex,
// }
// margin-left: 220px;
// /* margin-bottom: -4px; */
// margin-top: -15px;

function Complete(text) {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedrowList, setSelectedRowList] = useState([]);


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  const [ReportTitle, setReportTitle] = useState('');
  // const ReportTile=`Tiered Dev Report: ${parentGroup} → ${childGroup} → ${grandChildGroup}`;
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23, 59, 0, 0));
  const [productCategory, setProductCategory] = useState('');
  const [productCategoryArray, setProductCategoryArray] = useState([]);
  const [AFFID, setAFFID] = useState('');
  const [AFFIDArray, setAFFIDArray] = useState([]);
  const [C1, setC1] = useState('');
  const [C1Array, setC1Array] = useState([]);
  const [group, setgroup] = useState('');
  const [parentGroup, setParentGroup] = useState('Group Category');
  const [parentGroupFilterValue, setParentGroupFilterValue] = useState('')
  const [parentGroupArray, setParentGroupArray] = useState([])
  const [childGroup, setChildGroup] = useState('');
  const [childGroupFilterValue, setChildGroupFilterValue] = useState('')
  const [childGroupArray, setChildGroupArray] = useState([])
  const [grandChildGroup, setGrandChildGroup] = useState('');
  const [grandChildFilterValue, setGrandChildFilterValue] = useState('');
  const [grandChildGroupArray, setGrandChildGroupArray] = useState([])
  const [groupArray, setgroupArray] = useState([]);

  const [REFCB, setREFCB] = useState(true);
  const [Refund, setRefund] = useState('9');
  const [CB, setCB] = useState('1');
  

  const [groupCategory, setGroupCategory] = useState([
    // {label:"",value:"",group:""},
    { label: "Group Category", value: "Group Category", group: "Group Category" },
    { label: "Affiliate ID", value: "Affiliate ID", group: "Affiliate ID" },
    { label: "Campaign ID", value: "Campaign ID", group: "Campaign ID" },
    { label: "EF Offer ID (C1)", value: "EF Offer ID (C1)", group: "EF Offer ID (C1)" },
    { label: "Gateway Alias", value: "Gateway Alias", group: "Gateway Alias" },
    { label: "Product ID", value: "Product ID", group: "Product ID" },
    { label: "Campaign Group", value: "Campaign Group", group: "Campaign Group" },
    // 'AFFID',
    // 'Campaign.Id',
    // 'C1',
    // 'Gateway.Alias',
    // 'Product.Id',
    // 'Campaign.Group'
  ]);
  let type = '';
  if (parentGroup == 'Group Category') {
    type = 'category';
  } else if (parentGroup == 'Affiliate ID') {
    type = 'affid'
  } else if (parentGroup == 'Campaign ID') {
    type = 'campaign'
  }
  else if (parentGroup == 'EF Offer ID (C1)') {
    type = 'offer'
  } else if (parentGroup == 'Gateway Alias') {
    type = 'gateway'
  } else if (parentGroup == 'Product ID') {
    type = 'product'
  } else if (parentGroup == 'Campaign Group') {
    type = 'campaign_group'
  }


  let midTitle = '';
  const [reportType, setReportType] = useState(type);


  const location = useLocation()
  const reportOrderType=location.pathname.split("/").pop();

  let tempReportTitle = `Profits Report (ShopWare)`;

  if(reportOrderType=='wowsuite'){
    tempReportTitle = `Profits Report (Wowsuite)`;
  }






  const selectInputRef = useRef();
  const REFCBCheckBox = useRef();
  const selectChildGroupRef = useRef();
  const selectGrandChildGroupRef = useRef();


  const loadGroupFn = (inputValue) =>
    new Promise((resolve) => {
      let tempCategory = '';
      // console.log('Input='+inputValue);
      if (parentGroup == 'Group Category') {
        tempCategory = 'category';
      } else if (parentGroup == 'Affiliate ID') {
        tempCategory = 'affiliate'
      } else if (parentGroup == 'Campaign ID') {
        tempCategory = 'campaign'
      }
      else if (parentGroup == 'EF Offer ID (C1)') {
        tempCategory = 'offer'
      } else if (parentGroup == 'Gateway Alias') {
        tempCategory = 'gateway'
      } else if (parentGroup == 'Product ID') {
        tempCategory = 'product'
      } else if (parentGroup == 'Campaign Group') {
        tempCategory = 'campaign_group'
      }

      axios.get(baseUrl + '/reports/profitabilityDiagnosis/group?type=' + tempCategory, {}).then(response => {
        resolve(response.data.result.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ));

      });
    });

  const loadGroupChildFn = (inputValue) =>
    new Promise((resolve) => {
      let tempCategory = '';
      // console.log('Input='+inputValue);
      if (childGroup == 'Group Category') {
        tempCategory = 'category';
      } else if (childGroup == 'Affiliate ID') {
        tempCategory = 'affiliate'
      } else if (childGroup == 'Campaign ID') {
        tempCategory = 'campaign'
      }
      else if (childGroup == 'EF Offer ID (C1)') {
        tempCategory = 'offer'
      } else if (childGroup == 'Gateway Alias') {
        tempCategory = 'campaign'
      } else if (childGroup == 'Product ID') {
        tempCategory = 'campaign'
      } else if (childGroup == 'Campaign Group') {
        tempCategory = 'campaign_group'
      }

      axios.get(baseUrl + '/reports/profitabilityDiagnosis/group?type=' + tempCategory, {}).then(response => {
        resolve(response.data.result.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ));

      });
    });

  const loadGroupGrandChildFn = (inputValue) =>
    new Promise((resolve) => {
      let tempCategory = '';
      // console.log('Input='+inputValue);
      if (grandChildGroup == 'Group Category') {
        tempCategory = 'category';
      } else if (grandChildGroup == 'Affiliate ID') {
        tempCategory = 'affiliate'
      } else if (grandChildGroup == 'Campaign ID') {
        tempCategory = 'campaign'
      }
      else if (grandChildGroup == 'EF Offer ID (C1)') {
        tempCategory = 'offer'
      } else if (grandChildGroup == 'Gateway Alias') {
        tempCategory = 'campaign'
      } else if (grandChildGroup == 'Product ID') {
        tempCategory = 'campaign'
      } else if (grandChildGroup == 'Campaign Group') {
        tempCategory = 'campaign_group'
      }

      axios.get(baseUrl + '/reports/profitabilityDiagnosis/group?type=' + tempCategory, {}).then(response => {
        resolve(response.data.result.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ));

      });
    });

  const loanGroupCategoryFnParent = (inputValue) => {
    let tempCategory = groupCategory;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
    // loanGroupCategoryFnChild('1');
  }

  const loanGroupCategoryFnChild = (inputValue) => {

    let tempCategory = groupCategory;
    console.log(parentGroup)

    tempCategory = tempCategory.filter(i => i.group != parentGroup);
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  const loanGroupCategoryGrandChildFn = (inputValue) => {

    let tempCategory = groupCategory;

    tempCategory = tempCategory.filter(i => i.group != parentGroup && i.group != childGroup);
    console.log('filetered category')
    console.log(tempCategory);
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  //loanGroupCategory(inputValue,1)


  const loanGroupCategory = (inputValue, level) => {
    console.log('hey level');
    console.log(level);
    new Promise((resolve) => {
      if (level == '1') {
        // let array=groupCategory;
        // const index = array.indexOf(parentGroup);
        // if (index > -1) { // only splice array when item is found
        // array.splice(index, 1); // 2nd parameter means remove one item only
        // }
        // console.log('default')
        // console.log(groupCategory);
        // console.log('new')
        // console.log(array)
        // //resolve(array);
        console.log(groupCategory)
        console.log(parentGroup)
        console.log(inputValue)
        resolve(groupCategory.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ))

        //resolve()
      }
    });
  }


  const handleChange = (target) => {
    if (target != undefined) {
      setgroup(target.value);
    }
    //clearValue();
  }

  const handleChangeParentGroup = (target) => {
    //loanGroupCategory(1);

    if (target != undefined) {
      setParentGroup(target.value);
      console.log(selectChildGroupRef)
      //selectChildGroupRef.defaultOptions
      //selectChildGroupRef.loadOptions=loanGroupCategoryFnChild;
    }

  }
  const handleChangeChildGroup = (target) => {
    if (target != undefined) {
      setChildGroup(target.value);
    }
  }
  const handleChangeGrandChildGroup = (target) => {
    if (target != undefined) {
      setGrandChildGroup(target.value);
    }
  }
  const handleChangeParentGroupFilter = (target) => {
    if (target != undefined) {
      setParentGroupFilterValue(target.value);
    }
  }
  const handleChangeChildGroupFilter = (target) => {
    if (target != undefined) {
      setChildGroupFilterValue(target.value);
    }
  }
  const handleChangeGrandChildGroupFilter = (target) => {
    if (target != undefined) {
      setGrandChildFilterValue(target.value);
    }
  }



  /**************Adanced Filter  ***********/
  const [groupP, setgroupP] = useState('');

  const [groupT, setgroupT] = useState('');
  const [groupC, setgroupC] = useState('');
  const [groupG, setgroupG] = useState('');

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const selectInputRefInputFilter = useRef();
  const [filterLabel, setFilterLabel] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [filterArray, setFilterArray] = useState([]);

  const [filterValueT, setFilterValueT] = useState('')
  const [filterArrayT, setFilterArrayT] = useState([]);

  const [filterValueP, setFilterValueP] = useState('')
  const [filterArrayP, setFilterArrayP] = useState([]);

  const [filterValueC, setFilterValueC] = useState('')
  const [filterArrayC, setFilterArrayC] = useState([]);

  const [filterValueG, setFilterValueG] = useState('')
  const [filterArrayG, setFilterArrayG] = useState([]);


  const [selectLabelHeader, setSelectLabelHeader] = useState([]);
  const [selectLabelHeaderP, setSelectLabelHeaderP] = useState([]);
  const [selectLabelHeaderC, setSelectLabelHeaderC] = useState([]);
  const [selectLabelHeaderG, setSelectLabelHeaderG] = useState([]);


  const loadFilterLabel = (inputValue) => {
    let tempCategory = selectLabelHeader;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }
  const loadFilterLabelP = (inputValue) => {
    let tempCategory = selectLabelHeaderP;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }
  const loadFilterLabelC = (inputValue) => {
    let tempCategory = selectLabelHeaderC;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }
  const loadFilterLabelG = (inputValue) => {
    let tempCategory = selectLabelHeaderG;
    return new Promise((resolve) => {
      resolve(tempCategory.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
    });
  }

  const handleChangeFilterP = (e) => {
    if (e.target.value != undefined) {
      setFilterValueP(e.target.value);
    }
  }
  const handleChangeFilterC = (e) => {
    if (e.target.value != undefined) {
      setFilterValueC(e.target.value);
    }
  }
  const handleChangeFilterT = (e) => {
    if (e.target.value != undefined) {
      setFilterValueT(e.target.value);
    }
  }

  const handleChangeFilterG = (e) => {
    if (e.target.value != undefined) {
      setFilterValueG(e.target.value);
    }
  }


  const addFilterT = () => {
    if (groupT != '' && filterValueT != '') {
      let tempObj = { field: groupT, value: filterValueT };
      filterArrayT.push(tempObj);
      setFilterArrayT(filterArrayT);
      setFilterValueT('')
    }
  }

  const addFilterP = () => {
    if (groupP != '' && filterValueP != '') {
      let tempObj = { field: groupP, value: filterValueP };
      filterArrayP.push(tempObj);
      setFilterArrayP(filterArrayP);
      setFilterValueP('')
    }
  }
  const addFilterC = () => {
    if (groupC != '' && filterValueC != '') {
      let tempObj = { field: groupC, value: filterValueC };
      filterArrayC.push(tempObj);
      setFilterArrayC(filterArrayC);
      setFilterValueC('')
    }
  }

  const addFilterG = () => {
    if (groupG != '' && filterValueG != '') {
      let tempObj = { field: groupG, value: filterValueG };
      filterArrayG.push(tempObj);
      setFilterArrayG(filterArrayG);
      setFilterValueG('')
    }
  }

  const deleteAdvancedFilterPFn = (item, index) => {
    const arr = filterArrayP.filter((item, j) => j != index);
    setFilterArrayP(arr);
  }

  const deleteAdvancedFilterTFn = (item, index) => {
    const arr = filterArrayT.filter((item, j) => j != index);
    setFilterArrayT(arr);
  }

  const deleteAdvancedFilterCFn = (item, index) => {
    const arr = filterArrayC.filter((item, j) => j != index);
    setFilterArrayC(arr);
  }

  const deleteAdvancedFilterGFn = (item, index) => {
    const arr = filterArrayG.filter((item, j) => j != index);
    setFilterArrayG(arr);
  }

  const handleChangeT = (target) => {
    if (target != undefined) {
      setgroupT(target.value);
    }
  }

  const handleChangeP = (target) => {
    if (target != undefined) {
      setgroupP(target.value);
    }
  }

  const handleChangeC = (target) => {
    if (target != undefined) {
      setgroupC(target.value);
    }
  }
  const handleChangeG = (target) => {
    if (target != undefined) {
      setgroupG(target.value);
    }
  }

  /*********** Advanced Filter **********/

  const handleSignOut = () => {
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  checkUserTypeFn(history);

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;
    console.log('in check usertype fn');
    console.log(usertype)
    //   if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='user'){
    //     history.push('/');
    //  }

    if (usertype != 'owneradmin' && usertype != 'superadmin') {
      history.push('/');
    }
  }



  const [reportData, setReportData] = useState([])
  const [loader, setLoader] = useState(false)
  axios.defaults.timeout = 36000000;//10min
  const [message, setMessage] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  const [successMsg, setSuccessMsg] = useState('')

  useEffect(() => {
    console.log('Hello complete js')
    setSelectLabelHeader(objAdvancedFilter.getFilterColumns('transactions'))
    setSelectLabelHeaderP(objAdvancedFilter.getFilterColumns('products'))
    setSelectLabelHeaderG(objAdvancedFilter.getFilterColumns('gateways'))
    setSelectLabelHeaderC(objAdvancedFilter.getFilterColumns('campaigns'))

    let type = '';
    if (parentGroup == 'Group Category') {
      type = 'category';
    } else if (parentGroup == 'Affiliate ID') {
      type = 'affiliate'
    } else if (parentGroup == 'Campaign ID') {
      type = 'campaign'
    }
    else if (parentGroup == 'EF Offer ID (C1)') {
      type = 'offer'
    } else if (parentGroup == 'Gateway Alias') {
      type = 'campaign'
    } else if (parentGroup == 'Product ID') {
      type = 'campaign'
    } else if (parentGroup == 'Campaign Group') {
      type = 'campaign_group'
    }
    setReportType(type)



    

    setReportTitle(tempReportTitle)
  }, [parentGroup, childGroup, grandChildGroup])


  const ResetFilter = () => {
    if (REFCB == true)
      REFCBCheckBox.current.click();
    setMessage('')
    setEndDate(new Date(Date.now()))
    setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setgroup('');
    setREFCB(false);
    setRefund('');
    setCB('');
    setgroupArray([])
    setReportData([]);
    selectInputRef.current.clearValue();
  }



  const fetchApi = () => {
    let filterStringP = '';
    filterArrayP.forEach((i, j) => {
      filterStringP += `P.${i.field}=${i.value}*`
    })

    let filterStringT = '';
    filterArrayT.forEach((i, j) => {
      filterStringT += `T.${i.field}=${i.value}*`
    })
    let filterStringG = '';
    filterArrayG.forEach((i, j) => {
      filterStringG += `G.${i.field}=${i.value}*`
    })

    let filterStringC = '';
    filterArrayC.forEach((i, j) => {
      filterStringC += `C.${i.field}=${i.value}*`
    })

    if (startDate == null) {
      setMessage('Start Date must not be blank')
      return false;
    } if (endDate == null) {
      setMessage('End Date must not be blank')
      return false;
    } else {
      setMessage('')
    }
    let groupStr = groupArray.join('*');
    var response = {};
    response.data = {};
    response.data.result = [];




    let reportType = '';
    if (parentGroup == 'Group Category') {
      reportType = 'category';
    } else if (parentGroup == 'Affiliate ID') {
      reportType = 'affid'
    } else if (parentGroup == 'Campaign ID') {
      reportType = 'campaign'
    }
    else if (parentGroup == 'EF Offer ID (C1)') {
      reportType = 'offer'
    } else if (parentGroup == 'Gateway Alias') {
      reportType = 'gateway'
    } else if (parentGroup == 'Product ID') {
      reportType = 'product'
    } else if (parentGroup == 'Campaign Group') {
      reportType = 'campaign_group'
    }

    console.log(reportType)
    let API_URL = '';
    if (parentGroup != '' && childGroup != '' && grandChildGroup != '') {
      if (parentGroup == childGroup || childGroup == grandChildGroup || parentGroup == grandChildGroup) {
        console.log(parentGroup, childGroup, grandChildGroup)
        setMessage(`Can't have same two filters`)
        return false;
      }
    }

    setLoader(true);

    let parentFilteStr = parentGroupArray.join('*');
    let childFilterStr = childGroupArray.join('*');
    let grandChildFilteStr = grandChildGroupArray.join('*');

    console.log("parentFilteStr=" + parentFilteStr)
    console.log("childFilterStr=" + childFilterStr)
    console.log("grandChildFilteStr=" + grandChildFilteStr)

    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profitview/` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm') + "&type=" + reportType + "&group=" + groupStr + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB + "&parentGroup=" + parentGroup + "&childGroup=" + childGroup + "&grandChildGroup=" + grandChildGroup + "&filterStringP=" + filterStringP + "&filterStringT=" + filterStringT + "&filterStringC=" + filterStringC + "&filterStringG=" + filterStringG

    API_URL = API_URL + `&parentGroupFilter=${parentFilteStr}&childGroupFilter=${childFilterStr}&grandChildGroupFilter=${grandChildFilteStr}`

    API_URL=API_URL +`&reportOrderType=`+reportOrderType

    axios.get(API_URL, {}).then(response => {
      //axios.get(baseUrl+`/reports/profitabilityDiagnosis/${profit_type_report}/`+reportType+'?startdate='+moment(startDate).format('YYYY-MM-DD HH:mm')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm')+"&type=affid&group="+groupStr+"&refund="+Refund+"&cb="+CB+"&refcb="+REFCB, {}).then(response => {

      if (response.data.result.length > 0) {
        // console.log('data we have')
        response.data.result.forEach((i, j) => {

          try {

            i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString();
            i.AOV = "$" + formatNumberWithToFixed(i.AOV).toLocaleString();
            i.cpa_cost = "$" + formatNumberWithToFixed(i.cpa_cost).toLocaleString();
            i.cpa_cost_per_order = "$" + formatNumberWithToFixed(i.cpa_cost_per_order).toLocaleString();
            i.cogs = "$" + formatNumberWithToFixed(i.cogs).toLocaleString();
            i.cogs_per_order = "$" + formatNumberWithToFixed(i.cogs_per_order).toLocaleString();
            i.refund_dollars_amount = "$" + formatNumberWithToFixed(i.refund_dollars_amount).toLocaleString();
            i.chargebacks_dollars_amount = "$" + formatNumberWithToFixed(i.chargebacks_dollars_amount).toLocaleString();
            i.processing_cost = "$" + formatNumberWithToFixed(i.processing_cost).toLocaleString();
            i.processing_cost_per_order = "$" + formatNumberWithToFixed(i.processing_cost_per_order).toLocaleString();
            i.transaction_fee_cost = "$" + formatNumberWithToFixed(i.transaction_fee_cost).toLocaleString();
            i.transaction_fee_cost_per_order = "$" + formatNumberWithToFixed(i.transaction_fee_cost_per_order).toLocaleString();
            i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
            i.shipping_cost = "$" + formatNumberWithToFixed(i.shipping_cost).toLocaleString();
            i.shipping_cost_per_order = "$" + formatNumberWithToFixed(i.shipping_cost_per_order).toLocaleString();
            i.handling_cost = "$" + formatNumberWithToFixed(i.handling_cost).toLocaleString();
            i.handling_cost_per_order = "$" + formatNumberWithToFixed(i.handling_cost_per_order).toLocaleString();
            i.customer_service_cost = "$" + formatNumberWithToFixed(i.customer_service_cost).toLocaleString();
            i.customer_service_cost_per_order = "$" + formatNumberWithToFixed(i.customer_service_cost_per_order).toLocaleString();
            i.net_profit_per_order = "$" + formatNumberWithToFixed(i.net_profit_per_order).toLocaleString();


             

            // i.void_rate_percent=roundToTwo(i.void_rate_percent*100)+"%";

            // i.void_dollar_percent=roundToTwo(i.void_dollar_percent*100)+"%";

            // i.click_bump_rate=roundToTwo(i.click_bump_rate*100)+"%";

            // i.net_profit_margin=roundToTwo(i.net_profit_margin*100)+"%";

            i.profit_margin=roundToTwo(i.profit_margin*100)+"%";
            i.chargebacks_dollars_percent=roundToTwo(i.chargebacks_dollars_percent*100)+"%";

            i.chargebacks_rate_percent=roundToTwo(i.chargebacks_rate_percent*100)+"%";

            i.refund_dollars_percent=roundToTwo(i.refund_dollars_percent*100)+"%";

            i.refund_rate_percent=roundToTwo(i.refund_rate_percent*100)+"%";


          } catch (e) {
            console.log(e);
          }





        });
        setReportData(response.data.result);
        // console.log(response.data.result)
        setLoader(false)
        //setSuccessMsg('Data')
      } else {
        setLoader(false)
        setReportData([]);

      }

    }).catch(error => {
      setLoader(false)
      console.log(error);
      setMessage('Something went wrong');
    });
  }

  const fetchApiDownload = () => {



    let reportType = '';
    if (parentGroup == 'Group Category') {
      reportType = 'category';
    } else if (parentGroup == 'Affiliate ID') {
      reportType = 'affid'
    } else if (parentGroup == 'Campaign ID') {
      reportType = 'campaign'
    }
    else if (parentGroup == 'EF Offer ID (C1)') {
      reportType = 'offer'
    } else if (parentGroup == 'Gateway Alias') {
      reportType = 'gateway'
    } else if (parentGroup == 'Product ID') {
      reportType = 'product'
    } else if (parentGroup == 'Campaign Group') {
      reportType = 'campaign_group'
    }
    let groupStr = groupArray.join('*');
    console.log(reportType)
    let API_URL = '';
    if (parentGroup == '' && childGroup == '' && grandChildGroup == '') {
      console.log('521');
      setMessage('Please choose report type from filter')
      return false;
    }


    let parentFilteStr = parentGroupArray.join('*');
    let childFilterStr = childGroupArray.join('*');
    let grandChildFilteStr = grandChildGroupArray.join('*');

    console.log("parentFilteStr=" + parentFilteStr)
    console.log("childFilterStr=" + childFilterStr)
    console.log("grandChildFilteStr=" + grandChildFilteStr)

    API_URL = baseUrl + `/reports/profitabilityDiagnosis/profitview/download` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm') + "&type=" + reportType + "&group=" + groupStr + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB + "&parentGroup=" + parentGroup + "&childGroup=" + childGroup + "&grandChildGroup=" + grandChildGroup


    API_URL = API_URL + `&parentGroupFilter=${parentFilteStr}&childGroupFilter=${childFilterStr}&grandChildGroupFilter=${grandChildFilteStr}`



    window.location.href = API_URL;
  }

  const FilterSet = () => {
    // console.log('Filter resete');
    // console.log(group);
    console.log('=====')
    console.log(parentGroup);
    console.log(childGroup);
    console.log(grandChildGroup);
    console.log('====end =====')
    if (group != '') {
      if (inArrayForString(group, groupArray) == false) {
        setgroupArray(oldArray => [...oldArray, group]);
        setgroup('');
      }
    }

    selectInputRef.current.clearValue();

    // console.log('Group')
    // console.log(group);
    // console.log(groupArray);
    //setgroup('');
    //setProductCategoryStr();
  }

  const addParent = () => {
    // console.log('Hey')
    // console.log(parentGroup)
    // console.log(parentGroupFilterValue)
    // console.log('======')
    // console.log(parentGroupArray)
    if (parentGroupFilterValue != '') {
      if (inArrayForString(parentGroupFilterValue, parentGroupArray) == false) {
        console.log('572')
        setParentGroupArray(oldArray => [...oldArray, parentGroupFilterValue]);
        setParentGroupFilterValue('');
      }
    }
  }
  const addChild = () => {
    console.log('add Child')
    if (childGroupFilterValue != '') {
      if (inArrayForString(childGroupFilterValue, childGroupArray) == false) {
        console.log('580')
        setChildGroupArray(oldArray => [...oldArray, childGroupFilterValue]);
        setChildGroupFilterValue('');
      }
    }
  }
  const addGrandChild = () => {
    console.log('add Grand Child')
    console.log(grandChildFilterValue);
    console.log(grandChildGroupArray)
    if (grandChildFilterValue != '') {
      if (inArrayForString(grandChildFilterValue, grandChildGroupArray) == false) {
        console.log('605')
        setGrandChildGroupArray(oldArray => [...oldArray, grandChildFilterValue]);
        setGrandChildFilterValue('');
      }
    }
  }


  const deleteGroupFn = (item, index, group) => {

    if (group == 'parent') {
      console.log(item);
      removeItemOnce(parentGroupArray, item);
      console.log(parentGroupArray);

      let temp = parentGroupArray.filter((category) => category !== item);
      console.log(temp);
      setParentGroupArray(temp);
    } else if (group == 'child') {
      console.log(item);
      removeItemOnce(childGroupArray, item);
      console.log(childGroupArray);

      let temp = childGroupArray.filter((category) => category !== item);
      console.log(temp);
      setChildGroupArray(temp);

    } else if (group == 'grandChild') {
      console.log(item);
      removeItemOnce(grandChildGroupArray, item);
      console.log(grandChildGroupArray);

      let temp = grandChildGroupArray.filter((category) => category !== item);
      console.log(temp);
      setGrandChildGroupArray(temp);

    }

  }


  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const PrettoSlider = styled(Slider)({
    color: '#337ab7',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 10,
      background: 'unset',
      padding: 0,
      width: 25,
      height: 25,
      zIndex: -10,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#337ab7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  const resetChildGroup = () => {
    console.log('Child Group rested')
    setChildGroup('')
    console.log(childGroup);
    selectChildGroupRef.current.clearValue();
    setChildGroupArray([])
    resetGrandChildGroup();
    console.log('===========')
  }

  const resetGrandChildGroup = () => {
    console.log('Grand Child Group rested')
    setGrandChildGroup('')
    console.log(childGroup);
    selectGrandChildGroupRef.current.clearValue();
    setGrandChildGroupArray([])
    console.log('===========')
  }





  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />



      <div></div>
      <div></div>
      <div></div>
      <div></div>

      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>







      <section id="content-wrapper">

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className='FilterDivSection popupFilterSection'>


              <div className="input-bar-item width100">

                <div className='popupFilterElement'>
                  <label>Parent Group </label>

                  <AsyncSelect
                    // ref={selectInputRef}
                    hideSelectedOptions={false}
                    components={animatedComponents}
                    onChange={e => handleChangeParentGroup(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.value}
                    getOptionValue={(e) => e.value}
                    loadOptions={loanGroupCategoryFnParent}
                    defaultInputValue={parentGroup}
                  />
                </div>
                {parentGroup &&

                  <div className='popupFilterElement'>
                    <label>Parent Group Filter</label>
                    <AsyncSelect
                      // ref={ref => {
                      //   this.selectRef = ref;
                      // }}
                      key={JSON.stringify(parentGroup)}
                      // ref={selectInputRef}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={e => handleChangeParentGroupFilter(e)}
                      cacheOptions
                      defaultOptions
                      maxMenuHeight={250}
                      className="widthSelectInput"
                      classNamePrefix="select"
                      styles={colourStyles}
                      getOptionLabel={(e) => e.value}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadGroupFn}

                    />
                    <AddIcon style={AddIconClasses} onClick={addParent} />

                    <div className="FilterSection">
                      {/* {parentGroupArray.length>0 && 
 <span>Parent Group</span>
} */}
                      {/* <br></br>  */}
                      {parentGroupArray.map((item, index) => (
                        <span className="FilterSection">

                          <span className='filterCategory' id={index}><span className="filterSpan">{item}{ }</span>
                            <span onClick={(event) => deleteGroupFn(item, index, 'parent')}><CloseIcon className='closeIcon' /></span>
                          </span>
                          <br></br>
                          {/* <span className="whitespaceSpan">{ '            ' }</span> */}
                        </span>

                      ))}
                    </div>
                  </div>


                }



              </div>

              &nbsp;&nbsp;&nbsp;
              {parentGroup &&
                <div className="input-bar-item width100">
                  <div className='popupFilterElement'>
                    <label>Child Group</label>
                    <RestoreIcon style={RestoreIconClasses} onClick={resetChildGroup} />
                    <AsyncSelect
                      key={JSON.stringify(parentGroup) || JSON.stringify(childGroup)}
                      defaultOptions
                      ref={selectChildGroupRef}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={e => handleChangeChildGroup(e)}
                      cacheOptions
                      maxMenuHeight={250}
                      className="widthSelectInput"
                      classNamePrefix="select"
                      styles={colourStyles}
                      getOptionLabel={(e) => e.value}
                      getOptionValue={(e) => e.value}
                      loadOptions={loanGroupCategoryFnChild}
                      defaultInputValue={childGroup}
                    />
                  </div>
                  {childGroup &&
                    <div className='popupFilterElement'>
                      <label>Child Group Filter</label>
                      <AsyncSelect
                        // ref={ref => {
                        //   this.selectRef = ref;
                        // }}
                        key={JSON.stringify(childGroup)}
                        ref={selectInputRef}
                        hideSelectedOptions={false}
                        components={animatedComponents}
                        onChange={e => handleChangeChildGroupFilter(e)}
                        cacheOptions
                        defaultOptions
                        maxMenuHeight={250}
                        className="widthSelectInput"
                        classNamePrefix="select"
                        styles={colourStyles}
                        getOptionLabel={(e) => e.value}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadGroupChildFn}

                      />
                      <AddIcon style={AddIconClasses} onClick={addChild} />

                      <div className="FilterSection">
                        {/* {childGroupArray.length>0 &&
      <label>Child Group</label> 
    } */}
                        {childGroupArray.map((item, index) => (
                          <span className="FilterSection">

                            <span className='filterCategory' id={index}><span className="filterSpan">{item}{ }</span>
                              <span onClick={(event) => deleteGroupFn(item, index, 'child')}><CloseIcon className='closeIcon' /></span>
                            </span>
                            <br></br>
                            {/* <span className="whitespaceSpan">{ '            ' }</span> */}
                          </span>

                        ))}
                      </div>
                    </div>
                  }

                </div>
              }
              {childGroup &&
                <div className="input-bar-item width100">
                  <div className='popupFilterElement'>
                    <label>Grand Child Group</label>
                    <RestoreIcon style={RestoreIconClasses} onClick={resetGrandChildGroup} />
                    {/* <RestoreIcon style={RestoreIconClasses} onClick={resetChildGroup}/> */}
                    <AsyncSelect
                      ref={selectGrandChildGroupRef}
                      key={JSON.stringify(childGroup)}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={e => handleChangeGrandChildGroup(e)}
                      cacheOptions
                      defaultOptions
                      maxMenuHeight={250}
                      className="widthSelectInput"
                      classNamePrefix="select"
                      styles={colourStyles}
                      getOptionLabel={(e) => e.value}
                      getOptionValue={(e) => e.value}
                      loadOptions={loanGroupCategoryGrandChildFn}
                      defaultInputValue={grandChildGroup}
                    />

                  </div>
                  {grandChildGroup &&
                    <div className='popupFilterElement'>
                      <label>Grand Child Group Filter</label>
                      <AsyncSelect
                        // ref={ref => {
                        //   this.selectRef = ref;
                        // }}
                        key={JSON.stringify(grandChildGroup)}
                        ref={selectInputRef}
                        hideSelectedOptions={false}
                        components={animatedComponents}
                        onChange={e => handleChangeGrandChildGroupFilter(e)}
                        cacheOptions
                        defaultOptions
                        maxMenuHeight={250}
                        className="widthSelectInput"
                        classNamePrefix="select"
                        styles={colourStyles}
                        getOptionLabel={(e) => e.value}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadGroupGrandChildFn}

                      />
                      <AddIcon style={AddIconClasses} onClick={addGrandChild} />

                      <div className="FilterSection">
                        {/* {grandChildGroupArray.length>0 &&
      <label>Grand Child Group</label>
      } */}
                        {grandChildGroupArray.map((item, index) => (
                          <span className="FilterSection">

                            <span className='filterCategory' id={index}><span className="filterSpan">{item}{ }</span>
                              <span onClick={(event) => deleteGroupFn(item, index, 'grandChild')}><CloseIcon className='closeIcon' /></span>
                            </span>
                            <br></br>
                            {/* <span className="whitespaceSpan">{ '            ' }</span> */}
                          </span>

                        ))}
                      </div>
                    </div>
                  }

                </div>
              }
              <div className="input-bar-item closeModel">
                <CloseIcon className='closeIcon' onClick={handleClose} />
              </div>

              {/* <div className="input-bar-item">
    <button type="button"  onClick={FilterSet}  className="btn btn-primary">Filter</button> 
   
</div> */}
            </div>
          </Box>
        </Modal>




        <div className='FilterDivSection'>


          {/* <label>Parent Group</label> */}
          {/* <label className='groupStyle'>Group</label> */}

          <label className='startDateStyle'>Start Date</label>
          <label className='endDateStyle'>End Date</label>

          {!REFCB ? '' :
            <label className='RefundStyle'>Refund Percent</label>
          }
          {!REFCB ? '' :
            <label className='chargebackStyle'>Chargeback Percent</label>
          }

          <div className="input-bar">

            {/* <div className="input-bar-item width100">
    <div className="input-bar-item">
    <button type="button" onClick={handleOpen}   className="btn btn-primary">Groups</button> 
    </div>
    <div className="input-bar-item">
    </div>

    </div>
    &nbsp;&nbsp;&nbsp; */}

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={startDate}
                className='width140x'
                onChange={(date) => setStartDate(date)}
                placeholder='Start Date'
              />

            </div>

            &nbsp;&nbsp;&nbsp;

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={endDate}
                className='width140x'
                onChange={(date) => setEndDate(date)}
                placeholder='End Date'
              />

            </div>
            &nbsp;&nbsp;&nbsp;





            <div className="checkboxDiv">
              <FormGroup>
                <FormControlLabel control={<Checkbox
                  ref={REFCBCheckBox}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  name='Estimate Refunds/CBs'
                  value={REFCB}
                  defaultChecked={REFCB}
                  onChange={() => setREFCB(!REFCB)}
                />}
                  label="Estimate Refunds/CBs" />
              </FormGroup>
            </div>

            {!REFCB ? '' :


              <div className="input-bar-item">

                {/* <input 
     
     name='group'
     className='width62x'
     value={Refund}
     pattern="[0-9.]+" type="number"
     placeholder="Refunds"
     onChange={e => setRefund(e.target.value)} 
     
      /> */}
                {/* <div>
<Box width={200}>
      <Slider
        size="large"
        marks
        defaultValue={0}
        aria-label="Refunds"
        valueLabelDisplay="auto"
        min={0}
        max={15}
        onChange={e => setRefund(e.target.value)} 
      />
      
    </Box>
    </div> */}



                <Box width={150}></Box>

                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}

                  min={0}
                  max={15}
                  value={Refund}
                  onChange={e => setRefund(e.target.value)}
                  // valueLabelFormat={value => <div>{value}</div>}
                  step={1}
                  valueLabelDisplay="on"
                  marks


                />

                {/* <Slider
        valueLabelDisplay="on"
        defaultValue={2008}
        min={1998}
        max={2017}
        step={1}
      /> */}


              </div>
            }
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {!REFCB ? '' :

              <div className="input-bar-item">

                {/* <input 
    
     name='group'
     className='width92x'
     value={CB}
     pattern="[0-9.]+" type="number"
     placeholder="Chargebacks"
     onChange={e => setCB(e.target.value)} 
     
      /> */}

                {/* <Box sx={{ m: 3 }} /> */}
                <Box width={150}></Box>
                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={CB}
                  onChange={e => setCB(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <div className="input-bar-item">
    <button type="button"  onClick={FilterSet} className="btn btn-primary">Filter</button>
   
    </div> */}
            {/* <div className="input-bar-item">
    <button type="button" onClick={handleOpen1} className="btn btn-primary">Advanced Filter</button>

    </div> */}

            <div className="input-bar-item">
              <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

            </div>
            <div className='input-bar-item'>
              <button type="button" onClick={ResetFilter} className="btn btn-primary">Reset</button>
            </div>

            {/* <div className='downloadReportBtn'>
        {
          
          reportData.length>0 ? <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>:''
         
        }
       
      </div> */}

            <div className='downloadReportBtn'>
              {

                <button type="button" onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>

              }

            </div>
          </div>



          <div className="FilterSection">

            {parentGroupArray.map((item, index) => (
              <span className="FilterSection">
                <span className='filterCategory' id={index}><span className="filterSpan">Parent Group = {item}{ }</span>
                  <span onClick={(event) => deleteGroupFn(item, index, 'parent')}><CloseIcon className='closeIcon' /></span>
                </span>
                <span className="whitespaceSpan">{'            '}</span>
              </span>

            ))}
            {childGroupArray.map((item, index) => (
              <span className="FilterSection">

                <span className='filterCategory' id={index}><span className="filterSpan">Child Group ={item}{ }</span>
                  <span onClick={(event) => deleteGroupFn(item, index, 'child')}><CloseIcon className='closeIcon' /></span>
                </span>
                <br></br>
                <span className="whitespaceSpan">{'            '}</span>
              </span>

            ))}
            {grandChildGroupArray.map((item, index) => (
              <span className="FilterSection">

                <span className='filterCategory' id={index}><span className="filterSpan">Grand Child Group ={item}{ }</span>
                  <span onClick={(event) => deleteGroupFn(item, index, 'grandChild')}><CloseIcon className='closeIcon' /></span>
                </span>
                <br></br>
                <span className="whitespaceSpan">{'            '}</span>
              </span>

            ))}


            {filterArrayT.map((item, index) => (
              <>
                <span className='filterCategory' id={index}>
                  <span className="filterSpan">{item.field} = {item.value}</span>
                  <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item, index)} className='closeIcon' />
                </span>
                <span className="whitespaceSpan">{'            '}</span>
              </>
            ))}

            {filterArrayP.map((item, index) => (
              <>
                <span className='filterCategory' id={index}>
                  <span className="filterSpan">{item.field} = {item.value}</span>
                  <CloseIcon onClick={(event) => deleteAdvancedFilterPFn(item, index)} className='closeIcon' />
                </span>
                <span className="whitespaceSpan">{'            '}</span>
              </>
            ))}
            {filterArrayC.map((item, index) => (
              <>
                <span className='filterCategory' id={index}>
                  <span className="filterSpan">{item.field} = {item.value}</span>
                  <CloseIcon onClick={(event) => deleteAdvancedFilterCFn(item, index)} className='closeIcon' />
                </span>
                <span className="whitespaceSpan">{'            '}</span>
              </>
            ))}

            {filterArrayG.map((item, index) => (
              <>
                <span className='filterCategory' id={index}>
                  <span className="filterSpan">{item.field} = {item.value}</span>
                  <CloseIcon onClick={(event) => deleteAdvancedFilterGFn(item, index)} className='closeIcon' />
                </span>
                <span className="whitespaceSpan">{'            '}</span>
              </>
            ))}




          </div>
        </div>

        <div classname="advancedFilterSection">
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style_advanced}>


              <label>Transaction Header</label>
              <label className='filterValueStyle'>Filter Values</label>
              <div class="input-bar">

                <div className="input-bar-item width100">

                  <AsyncSelect
                    ref={selectInputRef}
                    components={animatedComponents}
                    onChange={e => handleChangeT(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadFilterLabel}
                  />
                </div>

                <>

                  &nbsp;&nbsp;&nbsp;
                  <div className="input-bar-item width100">
                    <input type='text'
                      ref={selectInputRefInputFilter}
                      className='width140x filterValue'
                      placeholder="Value"
                      onChange={e => handleChangeFilterT(e)}
                    />
                    <AddIcon style={AddIconClasses} onClick={addFilterT} />
                  </div>
                </>


                <div className="input-bar-item width100">
                  <div className="input-bar-item closeModel closeIconCommon">
                    <CloseIcon className='closeIcon' onClick={handleClose1} />
                  </div>
                </div>

              </div>

              <label>Products Header</label>
              <label className='filterValueStyle'>Filter Values</label>
              <div class="input-bar">

                <div className="input-bar-item width100">

                  <AsyncSelect
                    ref={selectInputRef}
                    components={animatedComponents}
                    onChange={e => handleChangeP(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadFilterLabelP}
                  />
                </div>

                <>

                  &nbsp;&nbsp;&nbsp;
                  <div className="input-bar-item width100">
                    <input type='text'
                      ref={selectInputRefInputFilter}
                      className='width140x filterValue'
                      placeholder="Value"
                      onChange={e => handleChangeFilterP(e)}
                    />
                    <AddIcon style={AddIconClasses} onClick={addFilterP} />
                  </div>
                </>




              </div>

              <label>Campaigns Header</label>
              <label className='filterValueStyle'>Filter Values</label>
              <div class="input-bar">

                <div className="input-bar-item width100">

                  <AsyncSelect
                    ref={selectInputRef}
                    components={animatedComponents}
                    onChange={e => handleChangeC(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadFilterLabelC}
                  />
                </div>

                <>

                  &nbsp;&nbsp;&nbsp;
                  <div className="input-bar-item width100">
                    <input type='text'
                      //  ref={selectInputRefInputFilter}
                      className='width140x filterValue'
                      placeholder="Value"
                      onChange={e => handleChangeFilterC(e)}
                    />
                    <AddIcon style={AddIconClasses} onClick={addFilterC} />
                  </div>
                </>


              </div>

              <label>Gateways Header</label>
              <label className='filterValueStyle'>Filter Values</label>
              <div class="input-bar">

                <div className="input-bar-item width100">

                  <AsyncSelect
                    ref={selectInputRef}
                    components={animatedComponents}
                    onChange={e => handleChangeG(e)}
                    cacheOptions
                    defaultOptions
                    maxMenuHeight={250}
                    className="widthSelectInput"
                    classNamePrefix="select"
                    styles={colourStyles}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadFilterLabelG}
                  />
                </div>

                <>

                  &nbsp;&nbsp;&nbsp;
                  <div className="input-bar-item width100">
                    <input type='text'
                      ref={selectInputRefInputFilter}
                      className='width140x filterValue'
                      placeholder="Value"
                      onChange={e => handleChangeFilterG(e)}
                    />
                    <AddIcon style={AddIconClasses} onClick={addFilterG} />
                  </div>
                </>


                {/* <div className="input-bar-item width100">
<div className="input-bar-item closeModel closeIconCommon"> 
<CloseIcon className='closeIcon' onClick={handleClose}/>
</div>
</div> */}

              </div>

              <div className="input-bar-item width100">
                <div className="FilterSection">

                  {filterArrayT.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{item.field} = {item.value}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item, index)} className='closeIcon' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}

                  {filterArrayP.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{item.field} = {item.value}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterPFn(item, index)} className='closeIcon' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}

                  {filterArrayC.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{item.field} = {item.value}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterCFn(item, index)} className='closeIcon' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}

                  {filterArrayG.map((item, index) => (
                    <>
                      <span className='filterCategory' id={index}>
                        <span className="filterSpan">{item.field} = {item.value}</span>
                        <CloseIcon onClick={(event) => deleteAdvancedFilterGFn(item, index)} className='closeIcon' />
                      </span>
                      <span className="whitespaceSpan">{'            '}</span>
                    </>
                  ))}
                </div>

              </div>




            </Box>
          </Modal>
        </div>





        {loader ?
          <div className='loaderDiv'>
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> :




          <MaterialTable
            // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}


            columns={[
              {
                title: "Group", field: "group",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              //   { title: "Name.of.Group", field: "groupname",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }
              //  },
              //   { 
              //     title: "Frontend", field: "front_end_count",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }
              // },
              {
                title: "Total.Orders", field: "total_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Total.Revenue", field: "total_revenue",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "AOV", field: "AOV",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Net.Profit", field: "net_profit",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Net.Profit.per.Order", field: "net_profit_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Profit.Margin", field: "profit_margin",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "CPA.Cost", field: "cpa_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "CPA.Cost.per.Order", field: "cpa_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "COGS", field: "cogs",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "COGS.per.Order", field: "cogs_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Shipping.Cost", field: "shipping_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Shipping.Cost.per.Order", field: "shipping_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number.of.Refunds", field: "number_of_refunds",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Refund.Rate.Percent", field: "refund_rate_percent",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Refund.Dollars.Amount", field: "refund_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Refund.Dollars.Percents", field: "refund_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Number.of.Chargebacks", field: "number_of_chargebacks",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Chargebacks.Rate.Percent", field: "chargebacks_rate_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Chargebacks.Dollars.Amount", field: "chargebacks_dollars_amount",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Chargebacks.Dollars.Percent", field: "chargebacks_dollars_percent",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing.Cost", field: "processing_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Processing.Cost.per.Order", field: "processing_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },
              {
                title: "Transaction.Fee.Cost", field: "transaction_fee_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Transaction.Fee.Cost.per.Order", field: "transaction_fee_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },

              {
                title: "Handling.Cost", field: "handling_cost",

                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Handling.Cost.per.Order", field: "handling_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },
              {
                title: "Customer.Service.Cost", field: "customer_service_cost",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }
              },



              {
                title: "Customer.Service.Cost.per.Order", field: "customer_service_cost_per_order",
                cellStyle: (e, rowdata) => {
                  if (rowdata.last == 1) {
                    return { fontWeight: 'bold' }
                  }
                }

              },



              // ,

              //   { title: "Number.of.Chargebacks", field: "chargeback_count",

              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },

              //   { title: "Chargebacks.Rate.Percent", field: "chargeback_rate_percent",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              //   { title: "Chargebacks.Dollars.Amount", field: "chargeback_dollar_amount",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              //   { title: "Chargebacks.Dollars.Percent", field: "chargeback_dollar_percent",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              //   { title: "Cashflow", field: "cashflow",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              //   { title: "Net.Profit", field: "net_profit",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              //   { title: "Net.Profit.per.FE", field: "net_profit_per_fe",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }
              // },
              //   { title: "Net.Profit.per.Order", field: "net_profit_per_order",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // },
              // { title: "Net.Profit.Margin", field: "net_profit_margin",
              //   cellStyle:(e,rowdata)=>{
              //     if(rowdata.last==1){
              //       return {fontWeight:'bold'}
              //     }
              //   }

              // }


            ]}
            data={
              reportData
            }
            title={ReportTitle}

            //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

            options={{
              // filtering:true,
              actionsColumnIndex: -1,
              addRowPosition: "first",
              pageSize: 10,
              loadingType: 'overlay',
              paging: false,
              exportButton: true,
              maxBodyHeight: '500px',

              headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
              })
            }}
          // components={{
          //   OverlayLoading: props => (<div>Heye</div>)
          // }}
          />
        }



      </section>

    </div>

  )
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function roundToFour(num) {
  return +(Math.round(num + "e+4") + "e-4");
}

function inArrayForString(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i].toLowerCase() == needle.toLowerCase())
      return true;
  }
  return false;
}


export default Complete