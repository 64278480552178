import React, { Fragment, useState } from 'react';
import Message from './Message';
import { useParams } from "react-router-dom"
import Progress from './Progress';
import axios from 'axios';
import axiosIntance from '../../helper/axios'
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { useHistory } from 'react-router';
import FilePlaceholder from './../../assets/download.png'
import FileIcon from './../../assets/google-docs.png'
import {baseUrl} from '../../config';
import Loader from 'react-loader-spinner';
const Download = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [successMsg,setSuccessMsg]=useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const history = useHistory();
  const [loader,setLoader]=useState(false)
  // axios.defaults.timeout = 36000000;//10min
  axios.defaults.timeout = 36000000;//10min
  const [uploadBtn,setUploadBtn]=useState(true)
  checkUserTypeFn(history);


  const { type } = useParams()
    let midTitle='';
    let tableId='';
     console.log(type);
     midTitle=type;
     tableId=type;
    if(type=='cs_codes'){
      tableId='css_codes';
      
    }else if(type=='cs_notes'){
      tableId='css_notes';
      
    }
    midTitle=type.toUpperCase();
    
    const [title,setTitle]=useState(type);


  

function checkUserTypeFn(history){
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const usertype=userdata.type;
  console.log('in check usertype fn');
  console.log(usertype)
  if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='databasehelper' && usertype!='owneradmin'){
    history.push('/');
 }

}

  const handleSignOut = () => {
    history.push('/');
  }
  if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
  }

  const onChange = e => {
    console.log('on changes fn*********');
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setUploadBtn(true)
    setSuccessMsg('');
    setMessage('');

  };
  const downloadFn = async e =>{
    console.log('download Fn Called')
    let tableName=tableId;
    setLoader(true)
    window.location.href=baseUrl+`/export/${tableName}`;
    setLoader(false)
  }
  

  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <section id="content-wrapper">
        <div className="row">
          <div className="col-md-12">
          <div className='uploadTitle'><h2>Download - {midTitle}</h2></div>
          
            <div className="overlay-content popup1 fileuploadcontainer">
            <div className='errorMsg'>{message}</div>
            <div className='successMsg uploadMsg'>{successMsg}</div>
            <div className='downloadExcelDiv'>
              {/* <a href={MyPDF} download="transactions.xlsx"> Download Sample Excel</a> */}
              </div>
              <div className='fileupload_div'>

                {message ? <Message msg={message} /> : null}
                <form>
                  <div className="custom-file mb-4">

                   
                    <label className='custom-file-label' htmlFor='customFile'>
                      {filename ?
                        <>
                          <div className="placeholder margin-bottom">
                            <img src={FileIcon} alt="file-placeholder" />
                          </div>
                          <label className="d-block">{filename}</label>
                        </>
                        :
                        <>
                          <div className="placeholder">
                            <img src={FilePlaceholder} alt="file-placeholder" onClick={downloadFn} />
                          </div>
                          {/* <label className="d-block">Click here to upload file</label> */}
                        </>
                      }
                    </label>
                  </div>
                  {
                    filename ?
                      <>
                        {/* <Progress percentage={uploadPercentage} /> */}
                        {loader && 
                        <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="100" width="100" /></div>
                        }

                        { uploadBtn && 
                        <div className="text-center">
                          <input
                            type="submit"
                            value="Upload"
                            className="btn btn-primary margin-bottom mt-8"
                          />
                        </div>
                          }
                      </>
                      :
                      ""
                  }

                </form>
                

              </div>
              {loader && 
                        <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="100" width="100" /></div>
                        }
            </div>


          </div>
        </div>
      </section>
    </div>
   
  );
};

export default Download;
