import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import axiosIntance, { fetchInstance, callAPI } from '../../../helper/axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom"
import MaterialTable from "material-table";
import Sidebar from '../../Template/Sidebar/Sidebar';
import Header from '../../Template/Header/Header';
import {baseUrl} from '../../../config';
import DatePicker from "react-datepicker";
import './takerate.css';
import moment from 'moment'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import "react-datepicker/dist/react-datepicker.css";
import {clickColor,inArray} from './../../../library/util';
import CloseIcon from '@mui/icons-material/Close';
import SummarizeIcon from '@mui/icons-material/Summarize';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';

import Typography from "@material-ui/core/Typography";
import {formatNumberWithToFixed} from '../../../library/util';


import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import AddIcon from '@mui/icons-material/Add';
import objAdvancedFilter from '../../../library/advancedFilterLibFn';

const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
      ...styles,
      background: 'white'
  }),
  option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? 'lightblue'
          : isSelected
              ? '#2a7bc0'
              : undefined,
      zIndex: 1
  }),
  menu: base => ({
      ...base,
      zIndex: 100
  })
  }
  const style_advanced = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const AddIconClasses={ color: '#337ab7',fontSize:'30px',marginLeft:'128px',marginTop:"-15px",cursor: 'pointer' };

const style = {
  position: 'absolute',
  
  // left: '50%',
  right:'-22%',
  padding:'3px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  align:'right',
  top: '61%',
  // top: '80%',
  width: '44%',
  height: '70%',
  maxHeight:'550px',
  overflowY: 'auto'
  
};

const styleCloseIcon={
  margin:'-27px'
}


function TakeRate() {
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);
    //const { type } = useParams()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [summary,setSummary]=useState([]);
    const [showSummaryDiv,setShowSummaryDiv]=useState(false)
    const [side1,setSide1]=useState([]);
    const [side2,setSide2]=useState([]);
    //let midTitle='';
    const [type,setType]=useState('')
    

     /************* Advanced Filter ***********/
   const [group,setgroup]=useState('');

   const [groupP,setgroupP]=useState('');

   const [groupT,setgroupT]=useState('');

  //  const [open, setOpen] = React.useState(false);
  //  const handleOpen = () => setOpen(true);
  //  const handleClose = () => setOpen(false);

   const selectInputRef = useRef();
   const selectInputRefInputFilter=useRef();
   const [filterLabel,setFilterLabel]=useState([])
   const [filterValue,setFilterValue]=useState('')
   const [filterArray,setFilterArray]=useState([]);

   const [filterValueT,setFilterValueT]=useState('')
   const [filterArrayT,setFilterArrayT]=useState([]);

   const [filterValueP,setFilterValueP]=useState('')
   const [filterArrayP,setFilterArrayP]=useState([]);
   const [selectLabelHeader,setSelectLabelHeader]=useState([]);
   const [selectLabelHeaderP,setSelectLabelHeaderP]=useState([]);
  
   const loadBrand = (inputValue) =>
   new Promise((resolve) => {
    axiosIntance.get(baseUrl+'/product/unique/brand', {}).then(response => {
        resolve(response.data.result.filter((i) =>
             i.label.toLowerCase().includes(inputValue.toLowerCase())
        ));
   
     });
   });
 const loadFilterLabel=(inputValue)=>
 {
   let tempCategory=selectLabelHeader;
   return new Promise((resolve) => {
     resolve(tempCategory.filter((i) =>
           i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
   });
 }
 const loadFilterLabelP=(inputValue)=>
 {
   let tempCategory=selectLabelHeaderP;
   return new Promise((resolve) => {
     resolve(tempCategory.filter((i) =>
           i.label.toLowerCase().includes(inputValue.toLowerCase())
      ))
   });
 }

 const handleChangeFilterP=(e)=>{
   if(e.target.value!=undefined){
     setFilterValueP(e.target.value);
   }
 }

 const handleChangeFilterT=(e)=>{
   if(e.target.value!=undefined){
     setFilterValueT(e.target.value);
   }
 }


 const addFilterT=()=>{
   if(groupT!='' && filterValueT!=''){
     let tempObj={field:groupT,value:filterValueT};  
       filterArrayT.push(tempObj);
       setFilterArrayT(filterArrayT);
       setFilterValueT('')
   }
 }

 const addFilterP=()=>{
   if(groupP!='' && filterValueP!=''){
     let tempObj={field:groupP,value:filterValueP};  
       filterArrayP.push(tempObj);
       setFilterArrayP(filterArrayP);
       setFilterValueP('')
   }
 }

 const deleteAdvancedFilterPFn = (item,index) =>{
  const arr = filterArrayP.filter((item,j) => j!=index);
  console.log(arr)
  setFilterArrayP(arr);
 }

 const deleteAdvancedFilterTFn = (item,index) =>{
  const arr = filterArrayT.filter((item,j) => j!=index);
  console.log(arr)
  setFilterArrayT(arr);
 }
const handleChangeT=( target )=> {
  console.log(target)
 if(target!=undefined){
  setType(target.value)
   setgroupT(target.value);
 }
}

const handleChangeP=( target )=> {
  
 if(target!=undefined){
   setgroupP(target.value);
 }
}
    
    
    
                    

    

    

    

    












    const reportName=`${type} Take Rate Report (ShopWare)`

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23,59,0,0));
    const [selectedrowList,setSelectedRowList]=useState([]);
    const handleSignOut = () => {
        window.localStorage.setItem('isLogin',false);
        history.push('/');
      }

      const setRowBackground = (selectedRow)=>{
        if(inArray(selectedRow.tableData.id,selectedrowList)==false){ 
        setSelectedRowList(oldArray => [...oldArray,selectedRow.tableData.id] );
        setSelectedRow(selectedRow.tableData.id)
        console.log(selectedrowList)
        }else{    
          setSelectedRow(500000)
          setSelectedRowList([])
        }
      }
  
   
    if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
      history.push('/');
    }

    checkUserTypeFn(history);

    function checkUserTypeFn(history){
      const userdata=JSON.parse(localStorage.getItem('userdata'));
      const usertype=userdata.type;
      console.log('in check usertype fn');
      console.log(usertype)
      if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='user' && usertype!='owneradmin'){
        history.push('/');
     }
    }
  
    const [reportData,setReportData]=useState([])
    const [loader,setLoader]=useState(false)
    
    const [message, setMessage] = useState('')
    const data = [['attribute', 'attribute2'], ['value1', 'value2']];
    const [successMsg,setSuccessMsg]=useState('')
    useEffect(() => {
      //fetchApi();
      //setSelectLabelHeader(objAdvancedFilter.getFilterColumns('transactions'))
      //setSelectLabelHeaderP(objAdvancedFilter.getFilterColumns('products'))
      //loadBrand();
      return () => {
        console.log('un subscribe')
        ResetFilter();
        setSummary([])
        setShowSummaryDiv(false)
      }
  
      
  
    }, [])
    
    const ResetFilter = () => {
      setMessage('')
      setEndDate(new Date(Date.now()))
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setReportData([]);
    }
   
  const fetchApi = () =>{
    if(type=='' || type==undefined){
      setMessage('Please select Brand');
      return false;
    }
    let filterStringP='';
    filterArrayP.forEach((i,j)=>{
      filterStringP+=`P.${i.field}=${i.value}*`
    })

    let filterStringT='';
    filterArrayT.forEach((i,j)=>{
      filterStringT+=`T.${i.field}=${i.value}*`
    })
    if(startDate==null){
      setMessage('Date must not be blank')
      return false;
    }else{
      setMessage('')
    }
    setLoader(true)
    axiosIntance.get(baseUrl+`/reports/takerate/${type}?startdate=`+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&filterStringP="+filterStringP+"&filterStringT="+filterStringT, {}).then(response => {
      let bigReport=response.data.result?.bigReport;
      
      if (bigReport.length > 0) {     
         //getSummary(response.data.result)
         bigReport.forEach((i,j)=>{
          i.estimatedRevenue="$"+formatNumberWithToFixed(i.estimatedRevenue);
          });

          

          showSideReport(response.data.result)
          setReportData(bigReport);
          setLoader(false)
        } else {
          setLoader(false)
          setReportData([]);
          setShowSummaryDiv(false)
        }
  
      }).catch(error => {
        setLoader(false)
        console.log(error)
        setMessage('Something went wrong');
      });
  }

  const fetchApiDownload = () =>{
    return false;
    let filterStringP='';
    filterArrayP.forEach((i,j)=>{
      filterStringP+=`P.${i.field}=${i.value}*`
    })

    let filterStringT='';
    filterArrayT.forEach((i,j)=>{
      filterStringT+=`T.${i.field}=${i.value}*`
    })
      window.location.href=baseUrl+`/reports/takerate/${type}/download?startdate=`+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&filterStringP="+filterStringP+"&filterStringT="+filterStringT;
  }

  const showSideReport=(result)=>{
    const side1=result['sideReport_1'];
    setSide1(side1)
    // const side2=result['sideReport_2'];
    const sideReport_2=result?.sideReport_2;
    sideReport_2.forEach((i,j)=>{
      i.takeratePercent=roundToTwo(i.takeratePercent*100)+"%"
      i.revenuePercent=roundToTwo(i.revenuePercent*100)+"%";
      i.aov=roundToTwo(i.aov);
    })
    setSide2(sideReport_2)
    
    // console.log(side1);
    
    // console.log('*********')
    // console.log(side2);
  }
  const getSummary=(result)=>{
    let unique = [...new Set(result.map(item => item.type))]; // [ 'A', 'B']
    let summary=[];
    
    let temptotalfrontend=getPercentValue(result,'Frontend');
    // if(midTitle=='Vital Flex Core'){
    //   temptotalfrontend=getPercentValue(result,'Stand Alone')+getPercentValue(result,'Bundle 1')+getPercentValue(result,'Bundle 2');
    // }
    let tempTotalRevenue=getRevenueSumValue(result);
    let tempAOV=roundToTwo(tempTotalRevenue/temptotalfrontend);

    summary.push(
      {type:'Frontend',
      sum:temptotalfrontend,
      revenueSum:tempTotalRevenue,
      aov:tempAOV
    }
      )
    
    unique.forEach((type)=>{
      let tempObj={};
      
      if(type!='Frontend' && type.toLowerCase()!='stand alone' && type.toLowerCase()!='bundle 1' && type.toLowerCase()!='bundle 2'){
        let tempsum=getPercentValue(result,type);
        let temprev=getRevenueValue(result,type);
        tempObj.type=type;
        tempObj.sum=tempsum
        tempObj.revenueSum=temprev
       
       
        if(summary[0].sum!=0){
          tempObj.percent=roundToTwo(tempObj.sum*100/summary[0].sum)+"%";
        }else{
          tempObj.percent="0"+"%";
        }
        
        tempObj.revenuetempPercent=temprev/summary[0].revenueSum
        if(summary[0].revenueSum!=0){
          tempObj.revenuePercent=roundToTwo(temprev*100/summary[0].revenueSum)+"%";
        }else{
          tempObj.revenuePercent="0"+"%";
        }
        
        if(temprev!=0){
          //tempObj.aov=roundToTwo(temprev/tempsum);
          tempObj.aov=roundToTwo(tempObj.revenuetempPercent*tempAOV)
        }
        else
        tempObj.aov=0;
       summary.push(tempObj);
      }
    })
    summary[0].revenueSum=formatNumberWithToFixed(summary[0].revenueSum);
    setSummary(summary)
    summary.length>0?setShowSummaryDiv(true):setShowSummaryDiv(false)

     console.log('========')
     console.log(summary)
    
  }

  function getPercentValue(result,type,all){
    console.log('all='+all)
    let sum=0;
    result.forEach((i)=>{
      if(type==i.type){console.log(type,i.type)
        sum+=parseInt(i.num_of_orders);
      }
    })
    return sum;
  }
  function getRevenueValue(result,type,all){
    console.log('all')
    console.log(all)
    console.log('end')
    let revenue=0;
    result.forEach((i)=>{
      if(type==i.type){
        revenue+=parseFloat(i.revenue);
      }
    })
    return revenue;
  }

  function getRevenueSumValue(result){

    let revenue=0;
    result.forEach((i)=>{
        revenue+=parseFloat(i.revenue);
    })
    return revenue;
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
   
   
    return (
  
      <div id="wrapper" className>
        <Sidebar />
        <Header handleSignOut={handleSignOut} />



  <div></div> 
  <div></div>
  <div></div> 
  <div></div>
     
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>
  
  
  
  
    
  
  
<section id="content-wrapper">
<div classname="advancedFilterSection">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
     <Box sx={style_advanced}>


<label>Transaction Header</label>
<label className='filterValueStyle'>Filter Values</label> 
<div class="input-bar">

<div className="input-bar-item width100">

<AsyncSelect
ref={selectInputRef}
components={animatedComponents}
onChange={e => handleChangeT(e)} 
  cacheOptions
  defaultOptions
  maxMenuHeight={250} 
  className="widthSelectInput"
  classNamePrefix="select"
    styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadFilterLabel}        
/>
</div>

<>

&nbsp;&nbsp;&nbsp;
<div className="input-bar-item width100">
  <input type='text'
     ref={selectInputRefInputFilter}
     className='width140x filterValue'
     placeholder="Value"
     onChange={e => handleChangeFilterT(e)} 
     />
  <AddIcon style={AddIconClasses} onClick={addFilterT}/>
</div>
</>

 
<div className="input-bar-item width100">
<div className="input-bar-item closeModel closeIconCommon"> 
<CloseIcon className='closeIcon' onClick={handleClose}/>
</div>
</div>

</div>

<label>Products Header</label>
<label className='filterValueStyle'>Filter Values</label> 
<div class="input-bar">

<div className="input-bar-item width100">

<AsyncSelect
ref={selectInputRef}
components={animatedComponents}
onChange={e => handleChangeP(e)} 
  cacheOptions
  defaultOptions
  maxMenuHeight={250} 
  className="widthSelectInput"
  classNamePrefix="select"
    styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadFilterLabelP}        
/>
</div>

<>

&nbsp;&nbsp;&nbsp;
<div className="input-bar-item width100">
  <input type='text'
     ref={selectInputRefInputFilter}
     className='width140x filterValue'
     placeholder="Value"
     onChange={e => handleChangeFilterP(e)} 
     />
  <AddIcon style={AddIconClasses} onClick={addFilterP}/>
</div>
</>

 
{/* <div className="input-bar-item width100">
<div className="input-bar-item closeModel closeIconCommon"> 
<CloseIcon className='closeIcon' onClick={handleClose}/>
</div>
</div> */}

</div>

<div className="input-bar-item width100">
<div className="FilterSection">

    {filterArrayT.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}

{filterArrayP.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterPFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}
</div>

</div>




     </Box>
     </Modal>
</div>



    <label>Start Date</label>
    <label className='endDateStyle'>End Date</label>
    <label className='brandStyle'>Brand</label>
    <div className="input-bar">
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={startDate}
      className='width140x'
      onChange={(date) => setStartDate(date)}
      placeholder='Start Date'

      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      />

    </div>
    
    &nbsp;&nbsp;&nbsp;
 
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={endDate}
      className='width140x'
      onChange={(date) => setEndDate(date)}
      placeholder='End Date'

      showTimeSelect
      timeFormat="p"
      timeIntervals={15}

      />
      
    </div>
    &nbsp;&nbsp;&nbsp;


<div className="input-bar-item width100">
<AsyncSelect
ref={selectInputRef}
components={animatedComponents}
onChange={e => handleChangeT(e)} 
defaultValue={{ label: "Select Brand", value: 0 }}
cacheOptions
defaultOptions
maxMenuHeight={250} 
className="widthSelectInput"
classNamePrefix="select"
  styles={colourStyles}
getOptionLabel={(e) => e.label}
getOptionValue={(e) => e.value}
loadOptions={loadBrand}        
/>
</div>


    {/* <div className="input-bar-item">
    <button type="button"  onClick={handleOpen}  className="btn btn-primary">Advanced Filter</button>
   
    </div> */}

    <div className="input-bar-item">
    <button type="button"  onClick={fetchApi} className="btn btn-primary">Run Report</button>
   
    </div>
    <div className='input-bar-item'>
    <button type="button"  onClick={ResetFilter} className="btn btn-primary">Reset</button>
    </div>

    

    {/* <div className='downloadReportBtn'>
        {
          
          reportData.length>0 ? <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>:''
         
        }
       
      </div> */}

      <div className='downloadReportBtn'>
        {
          
          <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>
         
        }
       
      </div>
     
      

     
  </div>
  {/* {summary.length>0 &&
  <div className='summaryIconDiv'>
    <SummarizeIcon className='summaryIcon' onClick={handleOpen}/>
   </div>
} */}

<div className="FilterSection">

    {filterArrayT.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}

{filterArrayP.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterPFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}
</div>

  {loader ?
  <div className='loaderDiv'>  
  <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> : 
  
      <>
          <MaterialTable style={{ maxWidth: '56%' }}
          // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

          onRowClick={((evt, selectedRow) => 
            setRowBackground(selectedRow)
            )}
            columns={[
              { title: "Brand", field: "brand",
              cellStyle:(e,rowdata)=>{
                if(rowdata.last==1){
                  return {fontWeight:'bold'}
                }
              }
             },
              { title: "Product_Number", field: "productNumber", 
              cellStyle:(e,rowdata)=>{
                if(rowdata.last==1){
                  return {fontWeight:'bold'}
                }
              }
            },{ title: "Product_Type", field: "productType", 
            cellStyle:(e,rowdata)=>{
              if(rowdata.last==1){
                return {fontWeight:'bold'}
              }
            }
          },{ title: "Product_Name", field: "productName", 
            cellStyle:(e,rowdata)=>{
              if(rowdata.last==1){
                return {fontWeight:'bold'}
              }
            }
          },
              { title: "SKU", field: "SKU",
            
            
              cellStyle:(e,rowdata)=>{
                if(rowdata.last==1){
                  return {fontWeight:'bold'}
                }
              }},
              { title: "Order_Count", field: "orderCount", 
              cellStyle:(e,rowdata)=>{
                if(rowdata.last==1){
                  return {fontWeight:'bold'}
                }
              }
            },
            { title: "Estimated_Revenue", field: "estimatedRevenue", 
            cellStyle:(e,rowdata)=>{
              if(rowdata.last==1){
                return {fontWeight:'bold'}
              }
            }
          }
            ]}
            data={
                reportData
            }
             title={reportName}
            // title={<MyNewTitle variant="h6" text="Gateway Baseline Generator Report" />}
            // components={{
            //   Toolbar: (props) => (
            //     <div
            //       style={{
            //         alignItems: "center",
            //         // justifyContent: "center",
            //         // display: "flex"
            //       }}
            //     >
            //       <MyNewTitle {...props} />
            //     </div>
            //   )
            // }}
            options={{
                actionsColumnIndex: -1,
                addRowPosition: "first",
                pageSize:10,
                loadingType: 'linear',
                paging: false,
                exportButton: true,
                maxBodyHeight: '500px',
                headerStyle: { position: 'sticky', top: 0,fontSize:'17px',fontWeight:'bold' },
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
                })

             
                // maxBodyHeight: "60vh",
                // headerStyle: { position: 'sticky', top: 0,fontSize:'17px',fontWeight:'bold' },
                // maxBodyHeight: 600,
                // exportFileName:"test excel",
                //  exportDelimiter:""
              }}
             
          />

          <div>
      

{/* <div className="closeIconCSSTakeRate"> 
<CloseIcon className='closeIcon' onClick={handleClose}/>
</div> */}
{showSummaryDiv &&
<div style={style} className="parent">
{summary.length>0 &&
<>
<div className='column column1'>
<span><b>Total Frontends: {summary[0]?.sum}</b></span>
<br></br>
{summary.map((item,index)=>(
<div>
<span><b>{item?.type!='Frontend'?item.type+" : "+item?.percent:""}</b></span>
<br/>
<br/>
</div>
))
}
</div>


<div  className='column column2'>
<span><b>Total Revenue: ${summary[0]?.revenueSum}</b></span>
<br></br>
{summary.map((item,index)=>(
<div>
<span><b>{item?.type!='Frontend'?item.type+" : "+item?.revenuePercent:""}</b></span>
<br/>
<br/>
</div>
))
}
</div>



<div  className='column column3'>
<span><b>AOV: ${!isNaN(summary[0]?.aov)?summary[0]?.aov:0}</b></span>
<br></br>
{summary.map((item,index)=>(
<div>
<span><b>{item?.type!='Frontend'?item.type+" : $"+item?.aov:""}</b></span>
<br/>
<br/>
</div>
))
}
</div>
</>



}

</div>
}

{Object.keys(side1).length>0 &&
<div style={style} className="side1">

<div className='column column1'>
<span><b>Total Frontends Orders</b></span>
<br></br>

<div>
<span><b>{side1?.totalFrontendOrder}</b></span>
<br/>
<br/>
</div>
</div>

<div className='column column1'>
<span><b>Total Revenue</b></span>
<br></br>

<div>
<span><b>${side1?.totalRevenue}</b></span>
<br/>
<br/>
</div>
</div>

<div className='column column1'>
<span><b>AOV</b></span>
<br></br>

<div>
<span><b>${side1?.aov}</b></span>
<br/>
<br/>
</div>
</div>
</div>

}

{/* 

Side Report 2 

*/}

{side2.length>0 &&
<div style={style} className="side2">

<>
<div className='column column1'>
<span><b>Product Type</b></span>
<br></br>
{side2.map((item,index)=>(
<div>
<span><b>{item.productType}</b></span>
<br/>
<br/>
</div>
))
}
</div>


<div  className='column column2'>
<span><b>Take Rate Percent</b></span>
<br></br>
{side2.map((item,index)=>(
<div>
<span><b>{item.takeratePercent}</b></span>
<br/>
<br/>
</div>
))
}
</div>



<div  className='column column3'>
<span><b>Revenue Percent</b></span>
<br></br>
{side2.map((item,index)=>(
<div>
<span><b>{item.revenuePercent}</b></span>
<br/>
<br/>
</div>
))
}
</div>

<div  className='column column4'>
<span><b>Contribution to AOV</b></span>
<br></br>
{side2.map((item,index)=>(
<div>
<span><b>${item.aov}</b></span>
<br/>
<br/>
</div>
))
}
</div>
</>





</div>
}
  








          </div>
          </>

        
        }
        </section>
       
      </div>

    )
}



function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

export default TakeRate
