import React from 'react'
import './header.css';
import { useHistory } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
function Header({handleSignOut,welcomeMsg}) {
     const userdata=JSON.parse(localStorage.getItem('userdata'));
     const headerUserName=userdata?.username;
     const history = useHistory();

    const manageAccount = () => {
        history.push('/profile');
      }

      const SignOut = () => {
        window.localStorage.setItem('isLogin',false);
        history.push('/');
      }
      const callHome=()=>{
        console.log('here')
        history.push('/');
      }
    return (
        <div id="navbar-wrapper">
            <nav className="navbar navbar-inverse">
                <div className="container-fluid">
                    <div className="navbar-header">
                    <HomeIcon className='homeIcon' onClick={callHome}/>
                        <a href="#" className="navbar-brand" id="sidebar-toggle">
                            {/* <i className="fa fa-bars" aria-hidden="true" /> */}
                            
                        </a>
                        
                        <div className="sign-in-log">
                            {/* <span>{welcomeMsg}</span> */}
                            <ul>
                                {/* <li><a href="#">SIGN IN</a> /</li> */}
                                <li><b>{welcomeMsg}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                                <li><b>{headerUserName}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                                <li className='pointerCls' onClick={manageAccount}><a className="pointerCls border-a"  >My Profile</a></li>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <li className='pointerCls' onClick={SignOut}><a className="show-popup pointerCls border-a"  data-showpopup={1}>Sign Out</a></li>
                            </ul></div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header
