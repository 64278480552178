import React, { Fragment, useState } from 'react';
import Message from './Message';
import Progress from './Progress';
import axios from 'axios';
import './woworder.css'
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { useHistory } from 'react-router';
import FilePlaceholder from './../../assets/cloud-computing.png'
import FileIcon from './../../assets/google-docs.png'
import MyPDF from '../../assets/Excels/wow_order_sample.xlsx';
import {baseUrl} from '../../config';
import Loader from 'react-loader-spinner';
import axiosIntance from '../../helper/axios';
const FileUpload = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [successMsg,setSuccessMsg]=useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const history = useHistory();
  const [loader,setLoader]=useState(false)
  axios.defaults.timeout = 36000000;//10min
  checkUserTypeFn(history);
  const [uploadBtn,setUploadBtn]=useState(true)

function checkUserTypeFn(history){
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const usertype=userdata.type;
  console.log('in check usertype fn');
  console.log(usertype)
  if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='databasehelper' && usertype!='owneradmin'){
    history.push('/');
 }

}

  const handleSignOut = () => {
    history.push('/');
  }
  if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
  }

  const onChange = e => {
    console.log('on changes fn*********');
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setUploadBtn(true)
    setSuccessMsg('');
    setMessage('');

  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploadBtn(false)
    
    try {
      const res = await axiosIntance.post(baseUrl+`/woworder`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setLoader(true)
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 80) / progressEvent.total)));
          //setTimeout(() => setUploadPercentage(0), 10000);
        }
      });

      const { fileName, filePath } = res.data;
      if(res.data.success===true){
        setLoader(false)
        setSuccessMsg(res.data.message);
        setUploadPercentage(100)
        setFilename('')
      }else{
        setLoader(false)
        setMessage(res.data.message);
      }
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      setLoader(false)
      console.log(err)
      setMessage('Something went wrong');
    }
  }

  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <section id="content-wrapper">
        <div className="row">
          <div className="col-md-12">
          <div className='uploadTitle'><h2>Upload - WOWORDERS</h2></div>
            <div className="overlay-content popup1 fileuploadcontainer">
            <div className='errorMsg'>{message}</div>
            <div className='successMsg uploadMsg'>{successMsg}</div>
            <div className='downloadExcelDiv'>
              <a href={MyPDF} download="Orders_Sample.xlsx"> Download Sample Excel </a>
              </div>
              <div className='fileupload_div'>

                {message ? <Message msg={message} /> : null}
                <form onSubmit={onSubmit}>
                  <div className="custom-file mb-4">

                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={onChange}
                      accept=".xls,.xlsx"
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                      {filename ?
                        <>
                          <div className="placeholder margin-bottom">
                            <img src={FileIcon} alt="file-placeholder" />
                          </div>
                          <label className="d-block">{filename}</label>
                        </>
                        :
                        <>
                          <div className="placeholder">
                            <img src={FilePlaceholder} alt="file-placeholder" />
                          </div>
                          <label className="d-block">Click here to upload file</label>
                        </>
                      }
                    </label>
                  </div>
                  {
                    filename ?
                      <>
                        {/* <Progress percentage={uploadPercentage} /> */}
                        {loader && 
                        <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="100" width="100" /></div>
                        }
                        { uploadBtn && 
                        <div className="text-center">
                          <input
                            type="submit"
                            value="Upload"
                            className="btn btn-primary margin-bottom mt-8"
                          />
                        </div>
                        }
                      </>
                      :
                      ""
                  }

                </form>
                {uploadedFile ? <div className="row mt-5">
                  <div className="col-md-6 m-auto"></div>
                  <h3 classNAme="text-center">{uploadedFile.fileName}</h3>
                  <img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
                </div> : null}

              </div>
            </div>


          </div>
        </div>
      </section>
    </div>
    // <section className="bg-back">
    //   <div className="container">

    //   </div>
    // </section>
  );
};

export default FileUpload;