import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosIntance, { callAPI } from '../../helper/axios';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from '../../config';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { clickColor, inArray, validateEmail } from './../../library/util';
const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};

const successMsgStyle = {
  color: 'green',
  textAlign: 'center'
};

const selectBoxStyle = {
  width: '100%'
}

function UserEditForm(props) {
  const api_module = 'users'
  const history = useHistory();
  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  let { id } = useParams();
  id = atob(id);
  console.log(id);
  console.log('############')
  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const sidebaretype = userdata.type;
  console.log(userdata, sidebaretype)


  const notEditSuperAdmin = async () => {console.log('45')
    try {
      const response = await callAPI('GET', api_module + "/" + id, {});
      console.log(response.data)
      if (response.data.result[0].type == 'superadmin') {
        history.push('/')
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (sidebaretype != 'admin' && sidebaretype != 'superadmin' && sidebaretype != 'owneradmin' && userdata.id != id) {
    history.push('/')
  } else {
    if (userdata.type != 'superadmin') {
      notEditSuperAdmin();
    }
  }
  const [user, setUser] = useState({
    username: "",
    email: "",
    type: "",
    password: "",
    cpassword: "",
  });
  const { username, email, type, password, cpassword } = user;
  let userDetails;
  const onInputChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadUser();
    return () => {
      console.log('un subscribe')
    }
  }, []);
  const loadUser = async () => {
    const result = await axiosIntance.get(baseUrl + '/users/' + id);
    result.data.result.password = '';
    result.data.result.cpassword = '';
    setUser(result.data.result);
  };



  const handleSignOut = () => {
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  const [loading, setLoading] = useState(false);

  const [loginMsg, setLoginMsg] = useState('')


  const handleSubmit = async () => {
    setLoading(false);

    if (username == '') {
      setLoginMsg('Please enter username')
    }
    else if (email == '') {
      setLoginMsg('Please enter email')
    } else if (!validateEmail(email)) {
      setLoginMsg('Please enter valid email')
    }
    else if (type == '') {
      setLoginMsg('Please choose type')
    } else {
      console.log('in update API')

      if (password != '') {

        if (password.length < 8) {
          setLoginMsg('Please enter atleast 8 chatacter long password')
          return false;
        } else if (cpassword == '') {
          setLoginMsg('Please enter confirm password')
          return false;
        } else if (password != cpassword) {
          setLoginMsg('Password and confirm password does not match');
          return false;
        }
      }
      try {
        const response = await callAPI('PUT', api_module + "/" + id, { id: id, username: username, email: email, type: type, password: password });
        if (response.data.success == true) {
          setSuccessMsg('User updated successfully!');
          setLoginMsg('');
          setTimeout(() => {
            history.push('/users');
          }, 3000);
        } else if (response.data.success == false) {
          console.log(49)
          setLoginMsg(response.data.message);
        } else {
          console.log('In else 50')
        }
      } catch (e) {
        setLoading(false);
        setLoginMsg('Something went wrong');
      }
    }
  }





  return (
    // <section className="bg-back">
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="overlay-content popup1">
              <form>
                <h1>Edit User</h1>
                <p style={errorMsgStyle}>{loginMsg}</p>
                <p style={successMsgStyle}>{successMsg}</p>
                <div className="field">
                  <input type="text" required placeholder="Username"
                    name="username"
                    value={username}

                    onChange={e => onInputChange(e)} />
                  <span className="fa fa-user" />
                </div>
                <div className="field">
                  <input type="password" required placeholder="Password"
                    name="password"
                    value={password}
                    onChange={e => onInputChange(e)}
                  />
                  <span className="fa fa-eye-slash" />
                </div>
                <div className="field">
                  <input type="password" required placeholder="Confirm Password"
                    pattern="[a-zA-Z0-9-]+"
                    name="cpassword"
                    value={cpassword}
                    onChange={e => onInputChange(e)}
                  />
                  <span className="fa fa-eye-slash" />
                </div>
                <div className="field">
                  <input type="text" required placeholder="abc@gmail.com"
                    name="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    value={email}
                    onChange={e => onInputChange(e)}
                  />
                  <span className="fa fa-envelope" />
                </div>
                <div className="field">
                  <select name="type" placeholder="type"

                    value={type}
                    onChange={e => onInputChange(e)} style={selectBoxStyle}
                  >
                    <option value="">Choose...</option>
                    {sidebaretype == 'superadmin' &&
                      <option value="superadmin">Superadmin</option>
                    }
                    {(sidebaretype == 'owneradmin' || sidebaretype == 'superadmin') &&
                      <option value="owneradmin">Owner Admins</option>
                    }
                    {(sidebaretype == 'admin' || sidebaretype == 'superadmin' || sidebaretype == 'owneradmin') &&
                      <option value="admin">Admin</option>
                    }
                    {(sidebaretype == 'admin' || sidebaretype == 'superadmin' || sidebaretype == 'subadmin' || sidebaretype == 'owneradmin') &&
                      <option value="subadmin">Sub-Admin</option>
                    }
                    {(sidebaretype == 'admin' || sidebaretype == 'superadmin' || sidebaretype == 'subadmin' || sidebaretype == 'databasehelper' || sidebaretype == 'owneradmin') &&
                      <option value="user">User</option>
                    }
                    <option value="databasehelper">Database Helper</option>

                    {/* <option value="user">User</option> */}
                  </select>
                  {/* <span className="fa fa-envelope" /> */}
                </div>
                {/* <span className="forgot-pss">Forgot Password</span> */}
              </form>
              <button className="close-btn" onClick={handleSubmit} disabled={loading}>Save</button>

            </div>
          </div>
        </div>
      </div>

    </div>
    // </section>
  )

}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}


export default UserEditForm;