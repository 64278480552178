import React, { useState, useEffect,useRef } from 'react';
import { callAPI } from '../../helper/axios';
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './index.css'
import { clickColor, inArray,validateEmail } from './../../library/util';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import AddIcon from '@mui/icons-material/Add';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const selectBoxStyle = {
  
}
const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};
const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
      ...styles,
      background: 'white'
  }),
  option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? 'lightblue'
          : isSelected
              ? '#2a7bc0'
              : undefined,
      zIndex: 1
  }),
  menu: base => ({
      ...base,
      zIndex: 100
  })
  }

function OrderManagement() {
  let api_module='order-management';
  const [showSection, setShowSection] = useState(true);
  let editDeleteIcon = true;

  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedrowList, setSelectedRowList] = useState([]);
  const selectInputRef = useRef();
  const selectInputRefO = useRef();

  

  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }
  const [sourceOfOrder,setSourceOfOrder]=useState('');
  const handleChangeP=( target )=> {
    console.log(target.value)
    if(target!=undefined){
      setSourceOfOrder(target.value);
    }
   }

   const [orderType,setOrderType]=useState('');
   const handleChangeType=( target )=> {
  
    if(target!=undefined){
      setOrderType(target.value);
    }
   }

   
   const [selectLabelHeader,setSelectLabelHeader]=useState([]);
   const [selectLabelHeaderP,setSelectLabelHeaderP]=useState();
   const loadFilterLabelSource=(inputValue)=>
   {
    
     let tempCategory=[
      {
    "value":"wowsuite",
    "label":"Wowsuite"
      },
      {
    "value":"shopware",
    "label":"Shopware"
      }
    ];
     console.log(tempCategory)
     return new Promise((resolve) => {
       resolve(tempCategory.filter((i) =>
             i.label.toLowerCase().includes(inputValue.toLowerCase())
        ))
     });
   }

   const loadFilterLabelType=(inputValue)=>
   {
    
     let tempCategory=[
      {
    "value":"cancelled",
    "label":"Cancelled"
      },
      {
    "value":"duplicate",
    "label":"Duplicate"
      },
      {
        "value":"chargeback",
        "label":"Chargeback"
      }
    ];
     console.log(tempCategory)
     return new Promise((resolve) => {
       resolve(tempCategory.filter((i) =>
             i.label.toLowerCase().includes(inputValue.toLowerCase())
        ))
     });
   }


  const userdata = JSON.parse(localStorage.getItem('userdata'));
  const sidebaretype = userdata.type;
  if (sidebaretype != 'admin' && sidebaretype != 'superadmin' && sidebaretype != 'owneradmin') {
    history.push('/')
  }

  if (sidebaretype == 'superadmin') {
    editDeleteIcon = false;
  }



  /*** for User  form  */

  const [loading, setLoading] = useState(false);


  // const [error, setError] = useState(null);
  const [loginMsg, setLoginMsg] = useState('')


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }





  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  const currentUserData = JSON.parse(localStorage.getItem('userdata'));
  const currentUserId = currentUserData.id;


  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }
  const [cssCodesData, setcssCodesData] = useState([])

  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  // const type = useFormInput('');
  // const orderType = useFormInput('');
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  

  useEffect(() => {
    loadData();
    //setSelectLabelHeaderP(sourceOfOrderFilterArray)

    return () => {
      console.log('un subscribe')
    }
  }, [])

  let loadData = async () => {
    try {
      const response = await callAPI('GET', api_module, {})
      if (response.data.result.length > 0) {
        response.data.result.map((item, i) => {
          response.data.result[i].showeditdelete = false;
          // console.log(item.type,sidebaretype)
          // if (item.type == 'superadmin' && sidebaretype == 'admin') {
          //   response.data.result[i].showeditdelete = true;
          // }
          if (item.type == 'superadmin') {
            response.data.result[i].showeditdelete = true;
          }
        });
        console.log(response.data.result);
        setcssCodesData(response.data.result);
      }
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async (rowData) => {
    try {
      const response = await callAPI('DELETE', `${api_module}/${rowData.id}`, {})
      console.log(response)
      setSuccessMsg('Deleted!');
      if (currentUserId == rowData.id) {
        handleSignOut();
      }
    } catch (e) {
      setMessage('Something went wrong');
    }
  }

  const SubmitDelete = (rowData) => {
    confirmAlert({
      title: '',
      message: 'Are you sure want to delete the Order Id?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDataFn(rowData)
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }

  const addUserFn = (e) => {
    history.push('/addNotableOrder');
  }

  const EditUserFn = (data) => {
    //let encodeId = encode(data.id) // s%p4ts4x%e5 
    var encodedString = btoa(data.id);
    console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"
    history.push('/editUser/' + encodedString);
    //history.push('/editUser/'+data.id);
  }
 
  const filterWork=async()=>{
    let reportType=sourceOfOrder
    let orderTypeV=orderType
    api_module=api_module+`?order_type=${orderTypeV}&source_of_order=${reportType}`;
    try {
      const response = await callAPI('GET', api_module, {order_type:orderTypeV,source_of_order:reportType})
      if (response.data.result.length > 0) {
        setcssCodesData(response.data.result);
      }else{
        setcssCodesData(response.data.result);
      }
    } catch (error) {
      setMessage('Something went wrong');
    }
  }
  const ResetWork=async()=>{
    loadData();
  }



  return (
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />

    <div class="FilterDivSection">
    <label className='sourceOfOrderCl'>Source of Order</label>
    <label className='orderTypeCl'>Order Type</label>
    <div className="input-bar">
  
    <div className="input-bar-item width100">
  

<AsyncSelect
ref={selectInputRef}
components={animatedComponents}
onChange={e => handleChangeP(e)} 
  cacheOptions
  name="type" 
  placeholder="Source Of Order"
  defaultOptions
  maxMenuHeight={250} 
  className="widthSelectInput"
  classNamePrefix="select"
    styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadFilterLabelSource}        
/>
    
    </div>

   
  

<AsyncSelect
ref={selectInputRefO}
components={animatedComponents}
onChange={e => handleChangeType(e)} 
  cacheOptions
  placeholder="Order Type"
  defaultOptions
  maxMenuHeight={250} 
  className="widthSelectInput"
  classNamePrefix="select"
    styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadFilterLabelType}        
/>
    &nbsp;&nbsp;&nbsp;
 


    <div className="input-bar-item">
    <button type="button"  onClick={filterWork} className="btn btn-primary">Filter</button>
   
    </div>
    <div className='input-bar-item'>
    <button type="button"  onClick={ResetWork} className="btn btn-primary">Reset</button>
    </div>
    <div className='input-bar-item'>
        {
          <button type="button" onClick={addUserFn} className="btn btn-primary">Add Notable Order</button>

        }
    
    </div>

      
  </div>
  
  </div>
    

      
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>


      <section id="content-wrapper-1">
        <MaterialTable

          onRowClick={((evt, selectedRow) =>
            setRowBackground(selectedRow)
          )}

          columns={[
            { title: "Order Id", field: "order_id" },
            { title: "Order Type", field: "order_type" },
            { title: "Source of Order", field: "source_of_order" },
          ]}
          data={
            cssCodesData
          }
          title="Manage Notable Orders"

          actions={[

            rowData => ({
              icon: 'delete',
              tooltip: 'Delete Order Id',
              onClick: (event, rowData) => SubmitDelete(rowData),
              disabled: rowData.showeditdelete
            })
          ]}
          options={{
            actionsColumnIndex: -1,
            addRowPosition: "first",
            maxBodyHeight: '500px',
            headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
            })
            // pageSize:10
          }}
        />
      </section>


    




    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    console.log(e);
    setValue(e.value);
  }
  return {
    value,
    onChange: handleChange
  }
}




export default OrderManagement
