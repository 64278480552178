import React, { Component } from 'react';
import "./App.css";
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import HomePage from './components/Home/HomePage';

import everflow from './components/Everflow/Everflow';
import everflowUpload from './components/Everflow/FileUpload';



import DownloadTable from './components/Exports/Download';


import Users from './components/Users/Users';
import UsersForm from './components/Users/UserForm';
import UsersEditForm from './components/Users/UserEditForm';

import Welcome from './components/Welcome/Welcome';

import profile from './components/Profile/Profile';

import './App.css';

import Shipstation from './components/Shipstation/Shipstation';

import ShipstationUpload from './components/Shipstation/FileUpload'


import Customer from './components/Customer/Customer';

import CustomerUpload from './components/Customer/FileUpload'

import Order from './components/Order/Order';

import orderUpload from './components/Order/FileUpload'

import Product from './components/Product/Product';

import productUpload from './components/Product/FileUpload'

import offer from './components/Offer/Offer';

import offerUpload from './components/Offer/FileUpload'


import Affiliate from './components/Affiliate/Affiliate';

import AffiliateUpload from './components/Affiliate/FileUpload'


import CustomCPA from './components/CustomCPA/CustomCPA';

import CustomCPAUpload from './components/CustomCPA/FileUpload'

import Consumption from './components/reports/SalesPerformanceReports/Consumption';




import Saleschannel from './components/Saleschannel/Saleschannel';
import Country from './components/Country/country';

import cardknox from './components/Cardknox/Cardknox';

import cardknoxUpload from './components/Cardknox/FileUpload'


/******************** TakeRate Report *****************************/

import TakeRate from './components/reports/TakeRate/TakeRate';

/******************** TieredNew Report *****************************/

import TieredDev from './components/reports/ProfitabilityDiagnosis/js/TieredNew/Complete';

/**************  Decline Reason Report*********/

import DeclineReasonReport from './components/reports/DeclineReasonReport/DeclineReasonReport'
import Tags from './components/Tags/Tags';
import productmanfacture from './components/Productmanfacturer/Productmanufacturer';


import paypal from './components/Paypal/Paypal';

import paypalUpload from './components/Paypal/FileUpload'



import Woworder from './components/Woworder/Woworder';

import WoworderlUpload from './components/Woworder/FileUpload'


import WowCampaign from './components/Wow-Campaign/WowCampaign';

import ShipstationProducts from './components/Shipstation_Products/ShipstationProducts';


import WowConversions from './components/Wow_Conversions/WowConversions';


import AddFormOrder from './components/OrdersManagement/AddFormOrder'
import OrdersManagement from './components/OrdersManagement/OrdersManagement'


import CombinedProfit from './components/reports/ProfitabilityDiagnosis/combined/Complete';

// import ReportTypeSelectBox from './components/Common/ReportTypeSelectBox'

const Home = () => (
  <HomePage />
);

const Login = () => (
  <LoginPage />
);




class App extends Component {


  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route path="/home" component={Home} />

          {/* <Route  path="/uploadProducts" component={productsUpload} />
          <Route  path="/viewproducts" component={products} />
          */}
          <Route path="/uploadeverflow" component={everflowUpload} />
          <Route path="/viewEverflow" component={everflow} />



          {/* <Route  path="/uploadTransactions" component={TransactionsUpload} />
         
          <Route  path="/viewTransactions" component={Transactions} />
           */}


          <Route path="/addUser" component={UsersForm} />
          <Route path="/users" component={Users} />
          <Route path="/editUser/:id" component={UsersEditForm} />


          <Route path="/uploadShipments" component={ShipstationUpload} />

          <Route path="/viewShipments" component={Shipstation} />

          <Route path="/welcome" component={Welcome} />
          <Route path="/profile" component={profile} />

          <Route path="/download/:type" component={DownloadTable} />

          <Route path="/uploadCustomer" component={CustomerUpload} />

          <Route path="/viewCustomer" component={Customer} />

          <Route path="/uploadOrder" component={orderUpload} />

          <Route path="/viewOrder" component={Order} />

          <Route path="/uploadProduct" component={productUpload} />

          <Route path="/viewProduct" component={Product} />


          <Route path="/uploadOffer" component={offerUpload} />

          <Route path="/viewOffer" component={offer} />

          <Route path="/uploadAffiliate" component={AffiliateUpload} />

          <Route path="/viewAffiliate" component={Affiliate} />
          

          <Route path="/uploadCustomCPA" component={CustomCPAUpload} />

        <Route path="/viewCustomCPA" component={CustomCPA} />

        <Route path="/uploadCardknox" component={cardknoxUpload} />

        <Route path="/viewCardknox" component={cardknox} />

        <Route  path="/reports/salesperformance/consumption" component={Consumption} />

        <Route  path="/reports/TakeRate" component={TakeRate}  />

        {/* <Route  path="/reports/profitsdiagnosis/profitview" component={TieredDev}  /> */}

        <Route  path="/reports/profitsdiagnosis/profitview" component={CombinedProfit}  />

        

        <Route  path="/reports/DeclineReasonReport/" component={DeclineReasonReport}  />


        <Route path="/master/countries" component={Country} />

        <Route path="/master/saleschannel" component={Saleschannel} />

        <Route path="/master/tags" component={Tags} />

        <Route path="/master/manufacturer" component={productmanfacture} />

        <Route path="/uploadPaypal" component={paypalUpload} />

        <Route path="/viewPaypal" component={paypal} />


        <Route path="/uploadWoworder" component={WoworderlUpload} />

        <Route path="/viewWoworder" component={Woworder} />

        <Route path="/viewWowCampaign" component={WowCampaign} />


        <Route path="/viewShipstationProducts" component={ShipstationProducts} />

        <Route path="/viewWowConversions" component={WowConversions} />

        <Route path="/addNotableOrder" component={AddFormOrder} />

        <Route path="/manageNotableOrder" component={OrdersManagement} />

        {/* <Route path="/selectbox" component={ReportTypeSelectBox} /> */}

        

        
        

        

        


        </div>
      </Router>
    );
  }
}

export default App;