
import React, { Component, Fragment, useEffect,useState } from 'react';
// import NavBar from '../NavBar/NavBar';
import './Home.css'
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
window.jquery = window.$ = $

function HomePage(props) {
  const history = useHistory();
  var i=0;
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const homeUserName=userdata?.username;
  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin',false);
    history.push('/');
  }

  const [welcomeMsg, setWelcomeMsg] = useState(`Welcome ${homeUserName} to Clearer Data`); 
 
    console.log(localStorage.getItem('isLogin'))
    if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
       history.push('/');
    }
    
 

  useEffect(() => {
    
  }, [])

  return (
    <div id="wrapper" className>
      <Sidebar/>
      <Header handleSignOut={handleSignOut} welcomeMsg={welcomeMsg}/>
      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
          {/* <div className='welcome-cls'>
          {welcomeMsg}
            </div> */}
            {/* <div className="contant-sec">
              <h2 className="content-title">View: Chargeback Reporting</h2>
              <div className="filters-opt">
                <span>Filter:</span>
                <ul>
                  <li>DataRange <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                  <li>Gateway <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                  <li>AFFID <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                </ul>
                <button className="apply-btn">Apply</button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );


}
export default HomePage;