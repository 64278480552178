import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { useHistory,useLocation } from "react-router-dom";

import MaterialTable from "material-table";
import Sidebar from '../../Template/Sidebar/Sidebar';
import Header from '../../Template/Header/Header';
import {baseUrl} from '../../../config';
import DatePicker from "react-datepicker";
import './consumption.css';
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";


import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';
import {clickColor,inArray} from './../../../library/util';



import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import AddIcon from '@mui/icons-material/Add';
import objAdvancedFilter from '../../../library/advancedFilterLibFn';

const animatedComponents = makeAnimated();

const colourStyles = {
  menuList: styles => ({
      ...styles,
      background: 'white'
  }),
  option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? 'lightblue'
          : isSelected
              ? '#2a7bc0'
              : undefined,
      zIndex: 1
  }),
  menu: base => ({
      ...base,
      zIndex: 100
  })
  }
  const style_advanced = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 20,
    p: 4,
  };



const AddIconClasses={ color: '#337ab7',fontSize:'30px',marginLeft:'128px',marginTop:"-15px",cursor: 'pointer' };

const styleCloseIcon={
  margin:'-27px'
}



function Consumption() {
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date().setHours(23,59,0,0));

    const [sku, setSKU] = useState('');
    const [skuArray,setSKUArray] = useState([]);
    const [selectedrowList,setSelectedRowList]=useState([]);


    /************* Advanced Filter ***********/
    const [group,setgroup]=useState('');

    const [groupP,setgroupP]=useState('');

    const [groupT,setgroupT]=useState('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const selectInputRef = useRef('');
    const selectInputRefInputFilter=useRef();
    const [filterLabel,setFilterLabel]=useState([])
    const [filterValue,setFilterValue]=useState('')
    const [filterArray,setFilterArray]=useState([]);

    const [filterValueT,setFilterValueT]=useState('')
    const [filterArrayT,setFilterArrayT]=useState([]);

    const [filterValueP,setFilterValueP]=useState('')
    const [filterArrayP,setFilterArrayP]=useState([]);
    const [selectLabelHeader,setSelectLabelHeader]=useState([]);
    const [selectLabelHeaderP,setSelectLabelHeaderP]=useState([]);
    const [reportType,setReportType]=useState('');
    const location = useLocation()
    let reportTypeCategory=[
      {
        "value":"combined",
        "label":"Combined"
      },
      {
        "value":"shopware",
        "label":"ShopWare"
      },
      {
    "value":"wowsuite",
    "label":"WowSuite"
      }
      
    ]
    //const type=location.pathname.split("/").pop();

    const [type,setType]=useState('combined')
    
   
    let reportTitle=`Consumption Report (ShopWare)`;
    let tableColumns=[
      { title: "", field: "row_header"},
    { title: "HMEGA", field: "HMEGA" },
    { title: "MINISPKR", field: "MINISPKR" },
    { title: "EARDROPS", field: "EARDROPS" },
    { title: "MICROFLASHLITE", field: "MICROFLASHLITE" },
    { title: "PESTREPEL", field: "PESTREPEL" },
    { title: "EYETAPE", field: "EYETAPE" },
    { title: "TWEEZER", field: "TWEEZER" },
    { title: "UNDEREYE", field: "UNDEREYE" },
    { title: "CHARSTICK", field: "CHARSTICK" },

    { title: "BUGBAND", field: "BUGBAND" },
    { title: "BBPACK", field: "BBPACK" },
    { title: "BUGSNAPZ", field: "BUGSNAPZ" },

    { title: "DIFFUSER", field: "DIFFUSER" },
    { title: "SCENTPACK", field: "SCENTPACK" },

    { title: "USBSTICK", field: "USBSTICK" },
    { title: "FBLANKET", field: "FBLANKET" },
    { title: "APPLCABLE", field: "APPLCABLE" },
    { title: "ANDRCABLE", field: "ANDRCABLE" },
    { title: "POWER", field: "POWER" },
    { title: "DASHCAM", field: "DASHCAM" },
    { title: "PWRBNK", field: "PWRBNK" },
    { title: "FFOLDER", field: "FFOLDER" },
    { title: "BONEAUDIO", field: "BONEAUDIO" },
    { title: "LOOPTOY", field: "LOOPTOY" },
    { title: "SOCCERTOY", field: "SOCCERTOY" },
    { title: "FITWATCH", field: "FITWATCH" },
    { title: "SMARTWATCH", field: "SMARTWATCH" },
    { title: "BIGFLASHLITE", field: "BIGFLASHLITE" },
    { title: "GRINDERDISC", field: "GRINDERDISC" },  
   
    ];
    
    if(type=='wowsuite'){
      reportTitle=`Consumption Report (WowSuite)`;
    }
    if(type=='combined'){
      reportTitle=`Combined Consumption Report`;
    }
   
    const loadFilterLabelSource=(inputValue)=>
   {
    
     let tempCategory=reportTypeCategory;
     return new Promise((resolve) => {
       resolve(tempCategory.filter((i) =>
             i.label.toLowerCase().includes(inputValue.toLowerCase())
        ))
     });
   }
  const deleteAdvancedFilterPFn = (item,index) =>{
   const arr = filterArrayP.filter((item,j) => j!=index);
   console.log(arr)
   setFilterArrayP(arr);
  }

  const deleteAdvancedFilterTFn = (item,index) =>{
   const arr = filterArrayT.filter((item,j) => j!=index);
   console.log(arr)
   setFilterArrayT(arr);
  }



const [sourceOfOrder,setSourceOfOrder]=useState('');
const handleChangeP=( target )=> {
  console.log(target.value)
  if(target!=undefined){
    setType(target.value);
  }
 }
    
checkUserTypeFn(history);

function checkUserTypeFn(history){
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const usertype=userdata.type;
  if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='user' && usertype!='owneradmin'){
    history.push('/');
 }
}
    
    const handleSignOut = () => {
        window.localStorage.setItem('isLogin',false);
        history.push('/');
      }
    
      const setRowBackground = (selectedRow)=>{
        
        let tempObj={};
        if(inArray(selectedRow.tableData.id,selectedrowList)==false){ 
        setSelectedRowList(oldArray => [...oldArray,selectedRow.tableData.id] );
        setSelectedRow(selectedRow.tableData.id)
        }else{
          setSelectedRow(5000)
          removeItemOnce(selectedrowList,selectedRow.tableData.id);
          
        }

      }
   
    if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
      history.push('/');
    }
  
    const [reportData,setReportData]=useState([])
    const [loader,setLoader]=useState(false)
    
    const [message, setMessage] = useState('')
    const data = [['attribute', 'attribute2'], ['value1', 'value2']];
    const [successMsg,setSuccessMsg]=useState('')
    useEffect(() => {
      return () => {
        console.log('un subscribe')
      }
  
  
  
    }, [])
    
    const ResetFilter = () => {
      setMessage('')
      setEndDate(new Date(Date.now()))
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    }
   
  const fetchApi = () =>{
    let filterStringP='';
    filterArrayP.forEach((i,j)=>{
      console.log(i)
      filterStringP+=`P.${i.field}=${i.value}*`
    })

    let filterStringT='';
    filterArrayT.forEach((i,j)=>{
      console.log(i)
      filterStringT+=`T.${i.field}=${i.value}*`
    })
  
    
    if(startDate==null){
      setMessage('Date must not be blank')
      return false;
    }else{
      setMessage('')
    }
    setLoader(true)
    let SKUStr=skuArray.join('*');
    
axios.get(baseUrl+'/reports/salesperformance/consumption?startdate='+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&sku="+SKUStr+"&filterStringP="+filterStringP+"&filterStringT="+filterStringT+"&type="+type, {}).then(response => {

    
        if (response.data.result.length > 0) {
          console.log('data we have')
          response.data.result.forEach((i,j)=>{
            console.log(i);
            i.decline_percent=roundToTwo(i.decline_percent*100)+"%";
          });
          setReportData(response.data.result);
          setLoader(false)
        } else {
          setLoader(false)
          setReportData([]);
        }
  
      }).catch(error => {
        setLoader(false)
        setMessage('Something went wrong');
      });
  }

  const fetchApiDownload = () =>{
    let filterStringP='';
    filterArrayP.forEach((i,j)=>{
      console.log(i)
      filterStringP+=`P.${i.field}=${i.value}*`
    })

    let filterStringT='';
    filterArrayT.forEach((i,j)=>{
      console.log(i)
      filterStringT+=`T.${i.field}=${i.value}*`
    })
    let SKUStr=skuArray.join('*');
    console.log(SKUStr);
      window.location.href=baseUrl+'/reports/salesperformance/consumption/download?startdate='+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&sku="+SKUStr+"&filterStringP="+filterStringP+"&filterStringT="+filterStringT; 
  }
  const FilterSet=() =>{
    if(sku!=''){
      if(inArrayForString(sku,skuArray)==false){ 
      setSKUArray(oldArray => [...oldArray,sku] );
      setSKU('');
      }
    }   
    }

  const deleteSKUFn = (item,index) =>{
    removeItemOnce(skuArray,item);
    let temp = skuArray.filter((category) => category !== item);
    setSKUArray(temp);
  }
  
  
  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
   
   
    return (
  
      <div id="wrapper" className>
        <Sidebar />
        <Header handleSignOut={handleSignOut} />



  <div></div> 
  <div></div>
  <div></div> 
  <div></div>
     
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>
  
  
  
  
    
  
  
    <section id="content-wrapper">
    <label className='con_date_for_report'>Date for Report</label>
    <div class="input-bar">
    <div className="input-bar-item width80">
    <div style={{width: '230px',height:'10px'}}>
  <AsyncSelect
  ref={selectInputRef}
  components={animatedComponents}
  onChange={e => handleChangeP(e)} 
    cacheOptions
    name="type" 
    placeholder="Report Type"
    defaultOptions
    defaultValue={reportTypeCategory[0]}
    maxMenuHeight={200} 
    className="widthSelectInput"
    classNamePrefix="select"
    styles={colourStyles}
    getOptionLabel={(e) => e.label}
    getOptionValue={(e) => e.value}
    loadOptions={loadFilterLabelSource}
     
  />
  </div>
      
      </div>
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={startDate}
      className='datePickerCon'
      onChange={(date) => setStartDate(date)}
      placeholder='Start Date'
      // showTimeInput

      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      

      />



    </div>
    
    &nbsp;&nbsp;&nbsp;
 
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={endDate}
      className='datePickerCon'
      onChange={(date) => setEndDate(date)}
      placeholder='End Date'
      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      />
      
    </div>
    &nbsp;&nbsp;&nbsp;

    &nbsp;&nbsp;&nbsp;
 

   
    <div class="input-bar-item">
    <button type="button"  onClick={fetchApi} className="btn btn-primary">Run Report</button>
   
    </div>
    
    <div class='input-bar-item'>
    <button type="button"  onClick={ResetFilter} className="btn btn-primary">Reset</button>
    </div>

  
    
      <div className='downloadReportBtn'>
        {
          
          <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>
         
        }
       
      </div>
  </div>

  {/* <div className="FilterSection">
  
  {skuArray.map((item, index) => (
      <span className="FilterSection"> 
       <span className='filterCategory' id={index}><span className="filterSpan">SKU = {item}{ }</span>
       <span  onClick={(event) => deleteSKUFn(item,index)}><CloseIcon className='closeIcon'/></span>
       </span>
       <span className="whitespaceSpan">{ '            ' }</span>
       </span>
      
    ))}






    
  </div> */}

<div className="input-bar-item width100">
<div className="FilterSection">

    {filterArrayT.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}

{filterArrayP.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterPFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}
</div>

</div>

  {loader ?
  <div className='loaderDiv'>  
  <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> : 
  
  //setSelectedRow(selectedRow.tableData.id)
          <MaterialTable
          onRowClick={((evt, selectedRow) => 
            setRowBackground(selectedRow)
            )}
          
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            columns={tableColumns}
            data={
                reportData
            }
             title={reportTitle}
            
            options={{
                actionsColumnIndex: -1,
                addRowPosition: "first",
                pageSize:10,
                loadingType: 'linear',
                paging: false,
                exportButton: true,
                maxBodyHeight: '500px',
                headerStyle: { position: 'sticky', top: 0,fontSize:'17px',fontWeight:'bold' },
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
                })

             
                
              }}
              
          />
        }
        </section>
       
      </div>

    )
}

function inArrayForString(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
  if (haystack[i].toLowerCase() == needle.toLowerCase())
   return true;
  }
  return false;
 }

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

export default Consumption
