
import React, { Component, Fragment, useEffect } from 'react';
// import NavBar from '../NavBar/NavBar';
import './Welcome.css'
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
window.jquery = window.$ = $

function Welcome(props) {
  const history = useHistory();
  var i=0;
  const handleSignOut = () => {
    console.log('logout')
    window.sessionStorage.setItem('isLogin',false);
    history.push('/');
  }

 
    console.log(sessionStorage.getItem('isLogin'))
    if(sessionStorage.getItem('isLogin')==="false" || sessionStorage.getItem('isLogin')==null){
       history.push('/');
    }
    
  //   useEffect(() => {
  //     console.log(i++);
  //     setTimeout(function(){
  //   console.log('location reload')
  //   window.location.reload();
  //  }, 1000);
  //     }, []);

  useEffect(() => {
    
    
      
   
    // $(function() {
    //   var Accordion = function(el, multiple) {
    //     this.el = el || {};
    //     this.multiple = multiple || false;

    //     // Variables privadas
    //     var links = this.el.find('.link');
    //     // Evento
    //     links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
    //   }

    //   Accordion.prototype.dropdown = function(e) {
    //     var $el = e.data.el;
    //       $this = $(this),
    //       $next = $this.next();

    //     $next.slideToggle();
    //     $this.parent().toggleClass('open');

    //     if (!e.data.multiple) {
    //       $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
    //     };
    //   } 

    //   var accordion = new Accordion($('#accordion'), false);
    // });

  }, [$])

  return (
    <div id="wrapper" className>
      <Sidebar/>
      <Header handleSignOut={handleSignOut}/>
      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
            <div className='welcome-cls'>
            Welcome to Clearer Data
            </div>
            {/* <div className="contant-sec">
              <h2 className="content-title">View: Chargeback Reporting</h2>
              <div className="filters-opt">
                <span>Filter:</span>
                <ul>
                  <li>DataRange <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                  <li>Gateway <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                  <li>AFFID <i className="fa fa-chevron-circle-down" aria-hidden="true" /></li>
                </ul>
                <button className="apply-btn">Apply</button>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );


}
export default Welcome;