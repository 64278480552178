import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom"
import MaterialTable from "material-table";
import Sidebar from '../../Template/Sidebar/Sidebar';
import Header from '../../Template/Header/Header';
import {baseUrl} from '../../../config';
import DatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import {clickColor,inArray} from './../../../library/util';
import CloseIcon from '@mui/icons-material/Close';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from 'react-loader-spinner';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import AddIcon from '@mui/icons-material/Add';
import objAdvancedFilter from '../../../library/advancedFilterLibFn';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Toggle from '../../toggle/toggle';
const colourStyles = {
  menuList: styles => ({
      ...styles,
      background: 'white'
  }),
  option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? 'lightblue'
          : isSelected
              ? '#2a7bc0'
              : undefined,
      zIndex: 1
  }),
  menu: base => ({
      ...base,
      zIndex: 100
  })
  }
  const style_advanced = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


const AddIconClasses={ color: '#337ab7',fontSize:'30px',marginLeft:'128px',marginTop:"-15px",cursor: 'pointer' };

const styleCloseIcon={
  margin:'-27px'
}

const animatedComponents = makeAnimated();



function DeclineReasonReport() {
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);
    const { type } = useParams()
    console.log(type);
    let midTitle='';
     console.log(type);
    if(type=='campaign_id'){
      midTitle='Campaign ID'
    }else if(type=='campaign_group'){
      midTitle='Campaign Group';
    }else if(type=='gateway'){
      midTitle='Gateway';
    }else if(type=='offer_id'){
      midTitle='Offer';
    }

    const [toggle, setToggle] = useState(true);
    const [reportName, setReportName] = useState('Declines Report (Cardknox) by Total Volume');
    const handleToggleChange = () => {
    setToggle(!toggle);
    toggle?setReportName('Declines Report (Cardknox) by Customer'):setReportName('Declines Report (Cardknox) by Total Volume')
    };
    

    
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000))
    const [endDate, setEndDate] = useState(new Date(Date.now() - 864e5).setHours(23,59,0,0));
    const [selectedrowList,setSelectedRowList]=useState([]);
    const handleSignOut = () => {
        window.localStorage.setItem('isLogin',false);
        history.push('/');
      }

      const setRowBackground = (selectedRow)=>{
        if(inArray(selectedRow.tableData.id,selectedrowList)==false){ 
        setSelectedRowList(oldArray => [...oldArray,selectedRow.tableData.id] );
        setSelectedRow(selectedRow.tableData.id)
        console.log(selectedrowList)
        }else{    
          setSelectedRow(500000)
          setSelectedRowList([])
        }
      }
  
   
    if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
      history.push('/');
    }


     /************* Advanced Filter ***********/
     const [group,setgroup]=useState('');
     const [groupT,setgroupT]=useState('');
     const [open, setOpen] = React.useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);
     const selectInputRef = useRef();
     const selectInputRefInputFilter=useRef();
     const [filterValueT,setFilterValueT]=useState('')
     const [filterArrayT,setFilterArrayT]=useState([]);
     const [selectLabelHeader,setSelectLabelHeader]=useState([]);
     const [selectLabelHeaderP,setSelectLabelHeaderP]=useState([]);
 
 
  
     const loadFilterLabel=(inputValue)=>
     {
       let tempCategory=selectLabelHeader;
       return new Promise((resolve) => {
         resolve(tempCategory.filter((i) =>
               i.label.toLowerCase().includes(inputValue.toLowerCase())
          ))
       });
     }
 
 
 
   const handleChangeFilterT=(e)=>{
     if(e.target.value!=undefined){
       setFilterValueT(e.target.value);
     }
   }
 
 
   const addFilterT=()=>{
     if(groupT!='' && filterValueT!=''){
       let tempObj={field:groupT,value:filterValueT};  
         filterArrayT.push(tempObj);
         setFilterArrayT(filterArrayT);
         setFilterValueT('')
     }
   }
 
  
 
 
 
   const deleteAdvancedFilterTFn = (item,index) =>{
    const arr = filterArrayT.filter((item,j) => j!=index);
    console.log(arr)
    setFilterArrayT(arr);
   }
 const handleChangeT=( target )=> {
   if(target!=undefined){
     setgroupT(target.value);
   }
 }
 

    checkUserTypeFn(history);

    function checkUserTypeFn(history){
      const userdata=JSON.parse(localStorage.getItem('userdata'));
      const usertype=userdata.type;
      console.log('in check usertype fn');
      console.log(usertype)
      if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='user' && usertype!='owneradmin'){
        history.push('/');
     }
    }
  
    const [reportData,setReportData]=useState([])
    const [loader,setLoader]=useState(false)
    
    const [message, setMessage] = useState('')
    const data = [['attribute', 'attribute2'], ['value1', 'value2']];
    const [successMsg,setSuccessMsg]=useState('')
    useEffect(() => {
      //fetchApi();
      setSelectLabelHeader(objAdvancedFilter.getFilterColumns('transactions'))
      return () => {
        console.log('un subscribe')
        ResetFilter();
      }
  
      
  
    }, [midTitle])
    
    const ResetFilter = () => {
      setMessage('')
      setEndDate(new Date(Date.now()))
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setReportData([]);
    }
   
  const fetchApi = () =>{
    let filterStringT='';
    let toggleVal='';
    filterArrayT.forEach((i,j)=>{
      filterStringT+=`T.${i.field}=${i.value}*`
    })
    if(startDate==null){
      setMessage('Date must not be blank')
      return false;
    }else{
      setMessage('')
    }
    setLoader(true)
    
    
    if(toggle==true){
      toggleVal='volume';
    }else if(toggle==false){
      toggleVal='customer';
    }else{
      toggleVal='volume';
    }
    axios.get(baseUrl+`/reports/declineReport?startdate=`+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&filterStringT="+filterStringT+"&toggle="+toggleVal, {}).then(response => {
  
        if (response.data.result.length > 0) {
          console.log('data we have')
          response.data.result.forEach((i,j)=>{
            console.log(i);
            if(i.approval_rate!=undefined){
              i.approval_rate=roundToTwo(i.approval_rate)+"%";
            }
          });
          setReportData(response.data.result);
          
          console.log(response.data.result)
          setLoader(false)
        } else {
          setLoader(false)
          setReportData([]);
        }
  
      }).catch(error => {
        setLoader(false)
        setMessage('Something went wrong');
      });
  }

  const fetchApiDownload = () =>{
    let filterStringT='';
    let toggleVal='';
    if(toggle==true){
      toggleVal='volume';
    }else if(toggle==false){
      toggleVal='customer';
    }else{
      toggleVal='volume';
    }
    filterArrayT.forEach((i,j)=>{
      filterStringT+=`T.${i.field}=${i.value}*`
    })
     
      window.location.href=baseUrl+`/reports/declineReport/download?startdate=`+moment(startDate).format('YYYY-MM-DD HH:mm:ss')+"&enddate="+moment(endDate).format('YYYY-MM-DD HH:mm:ss')+"&filterStringT="+filterStringT+"&toggle="+toggleVal;
   
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
   
   
    return (
  
      <div id="wrapper" className>
        <Sidebar />
        <Header handleSignOut={handleSignOut} />



  <div></div> 
  <div></div>
  <div></div> 
  <div></div>
     
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>
  
  
  
  
    
  
  
    <section id="content-wrapper">

    <div classname="advancedFilterSection">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
<Box sx={style_advanced}>


<label>Transaction Header</label>
<label className='filterValueStyle'>Filter Values</label> 
<div class="input-bar">

<div className="input-bar-item width100">

<AsyncSelect
ref={selectInputRef}
components={animatedComponents}
onChange={e => handleChangeT(e)} 
  cacheOptions
  defaultOptions
  maxMenuHeight={250} 
  className="widthSelectInput"
  classNamePrefix="select"
    styles={colourStyles}
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  loadOptions={loadFilterLabel}        
/>
</div>

<>

&nbsp;&nbsp;&nbsp;
<div className="input-bar-item width100">
  <input type='text'
     ref={selectInputRefInputFilter}
     className='width140x filterValue'
     placeholder="Value"
     onChange={e => handleChangeFilterT(e)} 
     />
  <AddIcon style={AddIconClasses} onClick={addFilterT}/>
</div>
</>

 
<div className="input-bar-item width100">
<div className="input-bar-item closeModel closeIconCommon"> 
<CloseIcon className='closeIcon' onClick={handleClose}/>
</div>
</div>

</div>



<div className="input-bar-item width100">
<div className="FilterSection">

    {filterArrayT.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}


</div>

</div>




</Box>
</Modal>
</div>

    <label>Start Date</label>
    <label className='endDateStyle'>End Date</label>
    <div className="input-bar">
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={startDate}
      className='width140x'
      onChange={(date) => setStartDate(date)}
      placeholder='Start Date'

      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      />

    </div>
    
    &nbsp;&nbsp;&nbsp;
 
    <div className="input-bar-item width100">
    
    <DatePicker
      dateFormat="yyyy-MM-dd HH:mm"
      selected={endDate}
      className='width140x'
      onChange={(date) => setEndDate(date)}
      placeholder='End Date'

      showTimeSelect
      timeFormat="p"
      timeIntervals={15}

      />
      
    </div>
    &nbsp;&nbsp;&nbsp;
    <div className="input-bar-item">
    {/* <button type="button"  onClick={handleOpen}  className="btn btn-primary">Advanced Filter</button> */}
   
    {/* <Stack direction="row" spacing={5} alignItems="center">
        <Typography>Volume</Typography>
        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
        <Typography>Customer</Typography>
      </Stack> */}

      <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
    </div>

    <div className="input-bar-item">
    <button type="button"  onClick={fetchApi} className="btn btn-primary">Run Report</button>
   
    </div>
    <div className='input-bar-item'>
    <button type="button"  onClick={ResetFilter} className="btn btn-primary">Reset</button>
    </div>

    {/* <div className='downloadReportBtn'>
        {
          
          reportData.length>0 ? <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>:''
         
        }
       
      </div> */}

      <div className='downloadReportBtn'>
        {
          
          <button type="button"  onClick={fetchApiDownload} className="btn btn-primary">Download Report in Excel</button>
         
        }
       
      </div>
  </div>
  <div className="FilterSection">
    {filterArrayT.map((item, index) => (
      <>
        <span className='filterCategory' id={index}>
        <span className="filterSpan">{item.field} = {item.value}</span>
        <CloseIcon onClick={(event) => deleteAdvancedFilterTFn(item,index)} className='closeIcon'/>
        </span>
        <span className="whitespaceSpan">{ '            ' }</span>
        </>
    ))}
</div>

  {loader ?
  <div className='loaderDiv'>  
  <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /></div> : 
  
          <MaterialTable
          // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

          onRowClick={((evt, selectedRow) => 
            setRowBackground(selectedRow)
            )}
            columns={[
              { title: "Card.Type", field: "card_type",
              cellStyle:(e,rowdata)=>{
                if(rowdata.isGateway==1){
                  return {color:'blue',fontWeight:'bold'}
                }else if('approval_rate' in rowdata){
                  return {color:'green',fontWeight:'bold'}
                }
              }
             },
              { title: "Transactions", field: "transactions", 
              cellStyle:(e,rowdata)=>{
                if(rowdata.isGateway==1){
                  return {color:'blue',fontWeight:'bold'}
                }else if('approval_rate' in rowdata){
                  return {color:'green',fontWeight:'bold'}
                }
              }
            },
            { title: "Approvals", field: "approvals", 
              cellStyle:(e,rowdata)=>{
                if(rowdata.isGateway==1){
                  return {color:'blue',fontWeight:'bold'}
                }else if('approval_rate' in rowdata){
                  return {color:'green',fontWeight:'bold'}
                }
              }
            },
            { title: "Declines", field: "declines", 
              cellStyle:(e,rowdata)=>{
                if(rowdata.isGateway==1){
                  return {color:'blue',fontWeight:'bold'}
                }else if('approval_rate' in rowdata){
                  return {color:'green',fontWeight:'bold'}
                }
              }
            },
              { title: "Approval.Percent", field: "approval_percent",
            
            
              cellStyle:(e,rowdata)=>{
                if(rowdata.isGateway==1){
                  return {color:'blue',fontWeight:'bold'}
                }else if('approval_rate' in rowdata){
                  return {color:'green',fontWeight:'bold'}
                }
              }},
           
            ]}
            data={
                reportData
            }
             title={reportName}
         
            options={{
                actionsColumnIndex: -1,
                addRowPosition: "first",
                pageSize:10,
                loadingType: 'linear',
                paging: false,
                exportButton: true,
                maxBodyHeight: '500px',
                headerStyle: { position: 'sticky', top: 0,fontSize:'17px',fontWeight:'bold' },
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
                })

             
            
              }}
          
          />
        }
        </section>
       
      </div>

    )
}

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

export default DeclineReasonReport
