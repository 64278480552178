let transactions_table_col_for_advanced_filter=['order_id','created_by','bill_first','bill_last','bill_address1','bill_address2','bill_city',
                            'bill_state','bill_zip','bill_country','bill_phone','bill_email','ship_first','ship_last',
                            'ship_address1','ship_address2','ship_city','ship_state','ship_zip','ship_country','ship_phone',
                            'ship_email','ship_method_name','ship_method_description','ship_group_name','ship_group_code',
                             'order_total','date_of_sale','time_of_sale','tracking_number','payment','campaign_id',
                            'customer_number','credit_card_number','credit_card_expiration','prepaid_match','gateway_id',
                             'gateway_alias','gateway_processing_percent','gateway_reserve_percent','gateway_transaction_fee',
                             'gateway_chargeback_fee','gateway_descriptor','gateway_customer_service_number','processor_id',
                             'ip_address','ip_address_lookup','order_status','decline_reason','is_cascaded','original_gateway_id',
                             'original_decline_reason','shipped_date','is_fraud','fraud_date','is_chargeback','chargeback_date',
                              'chargeback_by','is_rma','rma_number','rma_reason','rma_date','rma_created_by','return','return_reason',
                              'is_recurring','recurring_date','retry_date','total_installments','transaction_number','auth_number',
                              'retrying','retries_left','retry_attempt','hold_date','is_void','void_amount','void_date','voided_by',
                              'is_refund','refund_amount','refund_date','refunded_by','afid','sid','affid','c1','c2','c3','bid','aid',
                              'opt','rebill_discount','billing_cycle','parent_order_id','product_id','product_name','product_attributes',
                               'is_product_shippable','product_price','product_sku_1','product_sku_2','product_category','description',
                               'quantity','declared_value','test','is_cancel','hold_type','currency','offer_id',
                               'billing_model_id','notes','customer_tags']


let products_table_col_for_advanced_filter=[
    'sku','product_name','product_category','is_reship','product_type','number_of_units','entity','brand','offer',
    'product_id','per_order_cogs','front_end','upsell','warranty','click_bump','dummy_product','continuity_offer',
    'shippable','prime_sku','parent_sku','ship_cost_per_fe','handling_cost_per_fe','cs_cost_per_fe','effective_date',
    'bundle','bundled_product_ids'
];

let campaigns_table_col_advanced_filter=['product_line','campaign_id','campaign_name','campaign_group','campaign_group_second',
    'campaign_group_alltraffic','group_category,payment_type','campaign_status','quick_note','campaign_classification',
    'affiliate_network_tied_to','cpa_paid_on_campaign','ho_offer_ids_tied_to','has_scrub','which_campaign_id_is_its_scrub',
    'is_scrub','scrub_for_which_campaign_id','scrub_percent','scrub_update','in_house_media'];

let gateways_table_col_advaced_filter=['gateway_id','gateway_alias','bank','signer'];

let cs_notes_table_col_advanced_filter=['note_left','note_contents','order_id','created_by','note_time'];

const getFilterColumns=(table)=>{
    let array=[];
    let prefix;
    if(table=='transactions'){
        prefix='T';
        array=transactions_table_col_for_advanced_filter;
    }else if(table=='campaigns'){
        prefix='C';
        array=campaigns_table_col_advanced_filter;
    }else if(table=='products'){
        prefix='P';
        array=products_table_col_for_advanced_filter;
    }else if(table=='gateways'){
        prefix='G';
        array=gateways_table_col_advaced_filter;
    }else if(table=='csnotes'){
        prefix='C';
        array=cs_notes_table_col_advanced_filter;
    }
    let retrunArray=[];
    array.forEach((i)=>{
        let temp={};
        temp.group=prefix+"."+i;
        temp.label=i;
        temp.value=i
        retrunArray.push(temp);
    })
    return retrunArray;
        
}

module.exports.getFilterColumns=getFilterColumns;
// module.exports.gateways_table_col_advaced_filter=gateways_table_col_advaced_filter;
// module.exports.campaigns_table_col_advanced_filter=campaigns_table_col_advanced_filter;
// module.exports.products_table_col_for_advanced_filter=products_table_col_for_advanced_filter;
// module.exports.transactions_table_col_for_advanced_filter=transactions_table_col_for_advanced_filter;