import React, { useState } from 'react';
import axios from 'axios';
import axiosIntance,{callAPI} from '../../helper/axios';
import { useHistory } from "react-router-dom";
import {baseUrl} from '../../config';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { clickColor, inArray,validateEmail } from './../../library/util';
const errorMsgStyle = {
  color: 'red',
  textAlign: 'center'
};
const successMsgStyle = {
  color: 'green',
  textAlign: 'center'
};

const selectBoxStyle = {
  width: '100%'
}


function UserForm(props) {
  const history = useHistory();
  const api_module='users';
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const sidebaretype=userdata?.type;
  if(sidebaretype!='admin' && sidebaretype!='superadmin' && sidebaretype!='owneradmin'){
  history.push('/')  
 }


  const handleSignOut = () => {
      console.log('logout')
      window.localStorage.setItem('isLogin',false);
      history.push('/');
  }

  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const cpassword = useFormInput('');
  const email = useFormInput('');
  const type = useFormInput('');
  const [loginMsg,setLoginMsg]=useState('')
  const [successMsg,setSuccessMsg]=useState('')
 

  const handleSubmit = async() => {  
    setLoading(false);
    console.log('len='+password.value.length);
    console.log('valid email='+validateEmail(email.value))
    if(username.value ==''){
      setLoginMsg('Please enter username')
    }
    else if(password.value ==''){
      setLoginMsg('Please enter password')
    }
    else if(password.value.length<8){
      setLoginMsg('Please enter atleast 8 chatacter long password')
    }else if(cpassword.value==''){
      setLoginMsg('Please enter confirm password')
    }else if(password.value!=cpassword.value){
      setLoginMsg('Password and confirm password does not match');
    }
    else if(email.value ==''){
      setLoginMsg('Please enter email')
    }else if(!validateEmail(email.value)){
      setLoginMsg('Please enter valid email')
    }
    else if(type.value ==''){
      setLoginMsg('Please choose type')
    }else{
      
      try{

      }catch(e){
        setLoading(false);
        setLoginMsg('Something went wrong');
      }
      const response=await callAPI('POST', api_module, { username: username.value, password: password.value,email:email.value,type:type.value });
      if(response.data.success==true) {
        setSuccessMsg('Users added successfully!');
        setLoginMsg('');
        setTimeout(() => {
          history.push('/users');
        }, 2000);
        
      }else if(response.data.success==false){
        setLoginMsg(response.data.message);
      }else{
        console.log('In else 50')
      }
    }
   
  }

  
 

 
    return (
      // <section className="bg-back">
      <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="overlay-content popup1">
              <form>
                <h1>Add User</h1>
                <p style={errorMsgStyle}>{loginMsg}</p>
                <p style={successMsgStyle}>{successMsg}</p>
                <div className="field">
                  <input type="text" {...username}  required placeholder="Username"   />
                  <span className="fa fa-user" />
                </div>
                <div className="field">
                  <input type="password" {...password}  required placeholder="Password" pattern="[a-zA-Z0-9-]+"  />
                  <span className="fa fa-eye-slash" />
                </div>
                <div className="field">
                  <input type="password" {...cpassword}  required placeholder=" Confirm Password" pattern="[a-zA-Z0-9-]+"  />
                  <span className="fa fa-eye-slash" />
                </div>
                <div className="field">
                  <input type="text" {...email}  required placeholder="abc@gmail.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                  <span className="fa fa-envelope" />
                </div>
                <div className="field">
                 <select name="type" {...type} placeholder="type" style={selectBoxStyle} >
                 <option value="">Choose...</option>
                    {sidebaretype=='superadmin' &&
                  <option value="superadmin">Superadmin</option>
                    }
                    {(sidebaretype=='owneradmin' || sidebaretype=='superadmin') &&
                  <option value="owneradmin">Owner Admins</option>
                    }
                    {(sidebaretype=='admin' || sidebaretype=='superadmin' || sidebaretype=='owneradmin') &&
                  <option value="admin">Admin</option>
                    }
                    {(sidebaretype=='admin' || sidebaretype=='superadmin' || sidebaretype=='subadmin' || sidebaretype=='owneradmin') &&
                    <option value="subadmin">Sub-Admin</option>
                    }
                  {(sidebaretype=='admin' || sidebaretype=='superadmin' || sidebaretype=='subadmin' || sidebaretype=='databasehelper' || sidebaretype=='owneradmin') &&
                  <option value="user">User</option>
                  }
                  <option value="databasehelper">Database Helper</option>
                   
                  </select>
                  {/* <span className="fa fa-user-friends" /> */}
                
                </div>
                {/* <span className="forgot-pss">Forgot Password</span> */}
              </form>
              <button className="close-btn" onClick={handleSubmit} disabled={loading}>Save</button>
              
            </div>        
          </div>
        </div>
      </div>
      
      </div>
    // </section>
    )
  
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default UserForm;